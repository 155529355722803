import React, { Component } from "react";
import { Row, Col, Tooltip } from "antd";
//import "./styles/App.css";
import "./../../styles/SelectCity.css";
import "./../../styles/header.css";

import worldMap from "./../../img/world-low-resolution.svg";
import bubble_select_city from "./../../img/bubble_select_city.svg";
// import bubble_plus_city from "./img/bubble_plus_city.svg";
// import plus_black from "./img/plus-black.svg";
import city_on_map from "./../../img/map_pointer.svg";
import go_to_generator from "./../../img/bubble_go_to_generator.svg";

import lisbonJSON from "../../data/UFP_Lisbon.json";
import bostonJSON from "../../data/UFP_Boston.json";

export default class SelectCity extends Component {
  getJSON(city) {
    console.log(city);
    let file;
    if (city === "lisbon") file = lisbonJSON;
    if (city === "boston") file = bostonJSON;

    var data = new Blob([JSON.stringify(file, null, 2)], {
      type: "text/json"
    });
    var csvURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement("a");
    document.body.appendChild(tempLink);
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "UFP_" + city + ".fpr");
    tempLink.target = "_self";
    tempLink.click();
  }
  render() {
    return (
      <div className="select-background">
        <div className="title-small">BUILD YOUR URBAN FOODPRINT</div>
        <div className="title-big">UFP Map</div>

        <div className="worldmap_container">
          <img
            className="world-map-select-city"
            //onClick={() => this.toggleLeft()}
            src={worldMap}
            alt="world-map"
          />
          <Tooltip
            title="Lisbon FOODprint"
            placement="topLeft"
            style={{ position: "fixed" }}
            className="lisboa"
          >
            <img
              className="lisboa"
              //onClick={() => this.toggleLeft()}
              src={city_on_map}
              alt="world-map"
              onClick={() => this.getJSON("lisbon")}
            />
          </Tooltip>
          <Tooltip
            title="Boston FOODprint"
            placement="topLeft"
            style={{ position: "fixed" }}
            className="boston"
          >
            <img
              className="boston"
              //onClick={() => this.toggleLeft()}
              src={city_on_map}
              alt="city"
              onClick={() => this.getJSON("boston")}
            />{" "}
          </Tooltip>
        </div>
        <div
          //className="bubble-select-city"
          //onClick={() => this.toggleLeft()}
          // src={bubble_select_city}
          style={{ backgroundImage: "url('" + bubble_select_city + "')" }}
          //   alt="bubble-select-city"
        />

        <img
          className="go-to-generator"
          onClick={() => this.props.scrollTo("main_form")}
          src={go_to_generator}
          alt="world-map"
        />
      </div>
    );
  }
}
