import React, { Component } from "react";
import { Row, Col } from "antd";
import { slide as Menu } from "react-burger-menu";
import "../styles/App.css";
import "../styles/Hero.css";
import "../styles/header.css";
import Hero from "../Hero";
//import SelectCity from "../SelectCity";
//import AboutResearch from "../AboutResearch";
import Assessment from "../Assessment";
//import MainForm from "../components/getYourFoodPrint/MainForm";
import TryHarvest from "../TryHarvest";
import Footer from "../components/footer";

class Home extends Component {
  constructor(props) {
    super(props);

    this.scrollTo = this.scrollTo.bind(this);
  }

  scrollTo(item) {
    console.log(item);
    window.scrollTo({
      top: document.getElementById(item).getBoundingClientRect().bottom,
      behavior: "smooth"
    });
  }

  render() {
    return (
      <>
        <Hero scrollTo={this.scrollTo} />
        <Assessment scrollTo={this.scrollTo} />

        <TryHarvest />

        <Footer />
      </>
    );
  }
}

export default Home;
