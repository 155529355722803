import React, { Component } from "react";

import "../styles/new_page/full_about.css";

import envelope from "../img/refactoredVersion/about/envelope.svg";
import Footer from "../components/footer";
import { Col, Row } from "antd";

class About extends Component {
  constructor(props) {
    super(props);

    this.scrollTo = this.scrollTo.bind(this);
  }

  scrollTo(item) {
    console.log(item);
    window.scrollTo({
      top: document.getElementById(item).getBoundingClientRect().bottom,
      behavior: "smooth"
    });
  }

  render() {
    return (
      <>
        <div style={{ backgroundColor: "#F6F6F6" }}>
          <div className="full_about">About </div>
          <Row type="flex" justify="center">
            <Col
              xs={{ span: 23 }}
              lg={{ span: 12, offset: 0 }}
              //className="about_box1"
            >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  style={{
                    //display: "table",
                    position: "relative",
                    backgroundColor: "#353535",
                    color: "white",
                    width: "450px",
                    height: "510px",
                    padding: "30px",
                    margin: "10px",
                    marginBottom: "80px"

                    //margin: "0 0 60px auto"
                    //margin: "60px"
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "30px",
                      lineHeight: "191%",
                      letterSpacing: "0.03em",
                      paddingBottom: "30px"
                    }}
                  >
                    Khadija Benis{" "}
                  </div>

                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "300",
                      fontSize: "15px",
                      lineHeight: "191%",
                      letterSpacing: "0.03em"
                    }}
                  >
                    Khadija Benis is an architect and urban planner. She is
                    currently a research associate at the MIT Sustainable Design
                    Lab. She completed her PhD in Sustainable Energy Systems
                    under the MIT Portugal Program. She developed a framework
                    for the environmental and economic sustainability assessment
                    of Building-Integrated Agriculture (BIA) in urban contexts.
                    At MIT, she developed HARVEST, a CAD-embedded tool for urban
                    food production simulation that works as a plugin for the
                    Urban Modeling Interface (UMI).
                  </div>
                  <img
                    style={{
                      position: "absolute",
                      bottom: "30px",
                      right: "30px",
                      cursor: "pointer"
                      // width: "50%"
                      // margin: "40px"
                    }}
                    onclick="location.href='mailto:xyz@abc.com';"
                    src={envelope}
                    alt="envelope"
                  />
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 23 }}
              // className="about_box2"
              lg={{ span: 12, offset: 0 }}
            >
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div
                  style={{
                    position: "relative",
                    backgroundColor: "#353535",
                    color: "white",
                    width: "450px",
                    height: "510px",
                    padding: "30px",
                    margin: "10px",
                    marginBottom: "80px"

                    //margin: "0 auto 60px 0"
                    //margin: "60px"
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "30px",
                      lineHeight: "191%",
                      letterSpacing: "0.03em",
                      paddingBottom: "30px"
                    }}
                  >
                    Christoph Reinhart{" "}
                  </div>

                  <div
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "300",
                      fontSize: "15px",
                      lineHeight: "191%",
                      letterSpacing: "0.03em"
                    }}
                  >
                    Christoph Reinhart is a building scientist and architectural
                    educator working in the field of sustainable building design
                    and environmental modeling. At MIT he is leading the
                    Sustainable Design Lab, an inter-disciplinary group with a
                    grounding in architecture that develops design workflows,
                    planning tools and metrics to evaluate the environmental
                    performance of buildings and neighborhoods.
                  </div>
                  <img
                    style={{
                      position: "absolute",
                      bottom: "30px",
                      right: "30px",
                      cursor: "pointer"
                      // width: "50%"
                      // margin: "50px"
                    }}
                    onclick="location.href='mailto:xyz@abc.com';"
                    src={envelope}
                    alt="envelope"
                  />
                </div>
              </div>
            </Col>
          </Row>

          {/* <div className="titulo_about">Christoph Reinhart </div>
          <div className="texto_about"></div> */}
        </div>
        <Footer />
      </>
    );
  }
}

export default About;
