import React, { Component } from "react";

import Footer from "../components/footer";
import SurveyHome from "../components/survey/SurveyHome";

class Survey extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedLanguage: "arabish" };
  }

  render() {
    return (
      <>
        <div
        //   style={{ backgroundColor: "#00F", height: "100vh", width: "100%" }}
        >
          <SurveyHome {...this.props} />
        </div>
        <Footer />
      </>
    );
  }
}

export default Survey;
