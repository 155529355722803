import React, { Component } from "react";

import graphLegend_AR from "./images/final_graph_foodlegend_AR.png";
import graphLegend from "./images/final_graph_foodlegend.svg";
import { Link } from "react-router-dom";
import { dicionary } from "../../dicionary";
import {
  getCountryFoodgroups,
  getSameGroupFoodgroups,
} from "./../../network/foodAndSurvey";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";

//const { Option } = Select;

class Survey_Page_final extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLanguage: "us_US",
      filledSurvey: null,
      totalCo2AllGroups: 0,
      countryData: null,
      sameGroupSurveyCO2: null,
      graphData: null,
    };
  }

  getAnswerFromProp(question) {
    let answer;
    let result;
    if (
      this.props.answers &&
      this.props.answers.find((answer) => answer.survey_question === question)
    ) {
      result = this.props.answers.find(
        (answer) => answer.survey_question === question
      ).answer;
    } else {
      result = null;
    }
    //  console.log("getAnswerFromProp! ", result);

    return result;
  }
  languageChanged(value) {
    console.log(`selected ${value}`);
    this.setState({ selectedLanguage: value });
  }

  async componentDidMount() {
    console.log("mounting");

    //GET USER SAME GROUP FOODGROUPS
    let groupOfUser = this.getAnswerFromProp("5_1_groupMember");
    //  console.log("userbelongs to group ", groupOfUser);

    let sameGroupSurveyStats = await getSameGroupFoodgroups(groupOfUser);

    //  console.log("groups - arrived: ", sameGroupSurveyStats);

    let sameGroupSurveyCO2 = sameGroupSurveyStats.foodGroupsAverageCO2;

    Object.keys(sameGroupSurveyStats).forEach((key) => {
      //zero all nulls groups - to fix graph problems
      if (!sameGroupSurveyStats[key]) {
        sameGroupSurveyStats[key] = 0;
      }
    });

    this.setState({
      sameGroupSurveyCO2,
      numberOfSurveysSameGroup: sameGroupSurveyStats.numberOfSurveys,
    });
    //get user same country data
    let country = this.getAnswerFromProp("3A_1_country");
    console.log("user is from:", country);
    let countryData = await getCountryFoodgroups(country);
    if (Object.entries(countryData).length > 0) {
      this.setState({
        countryData,
      });
    }

    //setTimeout(() => this.setState_GraphCO2Values(), 5000); //hack
    this.setState_GraphCO2Values();
    //////////////////////
  }

  getTotalCo2AllGroups() {
    let totalCO2AllGroups = 0;
    if (this.props.generalSurveyData.foodGroups) {
      //console.log(this.props.generalSurveyData.foodGroups);
      let foodGroups = this.props.generalSurveyData.foodGroups;
      Object.keys(foodGroups).forEach((key) => {
        if (!isNaN(foodGroups[key].co2)) {
          totalCO2AllGroups += parseFloat(foodGroups[key].co2);
          //  foodGroups[key].co2.toFixed(2);
          //totalCO2AllGroups += foodGroups[key].co2.toFixed(2);
        }
      });
      return totalCO2AllGroups.toFixed(2);
    } else return "...";
  }

  setState_GraphCO2Values() {
    let foodGroups = this.props.generalSurveyData.foodGroups;
    let totalCO2AllGroups = 0;
    let graphData;

    if (this.props.generalSurveyData.foodGroups) {
      let nameOfSameGroupBar = this.getAnswerFromProp("5_1_groupMember");

      if (nameOfSameGroupBar && nameOfSameGroupBar.length > 7) {
        nameOfSameGroupBar = nameOfSameGroupBar.substring(0, 7) + "... ";
      }
      let countryName = this.getAnswerFromProp("3A_1_country");
      if (countryName) {
        if (countryName === "United States") {
          countryName = "USA"; //country name reduced to fit in the graph
        }
        if (countryName && countryName.length > 13) {
          countryName = "Your Country"; //If its too big,  reduce to fit in the graph
        }
        countryName = countryName + "(**)";
      }

      Object.keys(foodGroups).forEach((key) => {
        if (!isNaN(foodGroups[key].co2)) {
          foodGroups[key].co2 = parseFloat(foodGroups[key].co2).toFixed(2);
        }
      });

      /*
          meat: 
          dairy_eggs:
          grain: 
          produce: 
          sugar_fat: 
          other: 
       
             meat: "لحوم",
    dairy_eggs: "البيض/الألبان",
    grain: "(القمح (الحبوب",
    produce: "محصول",
    sugar_fat: "السكر/الدهون",
    other: "أخرى",
      
*/
      graphData = [
        {
          name: dicionary[this.props.selectedLanguage]["you"],
          [dicionary[this.props.selectedLanguage]["meat"]]:
            foodGroups["meat"].co2,
          [dicionary[this.props.selectedLanguage]["dairy_eggs"]]:
            foodGroups["dairy_eggs"].co2,
          [dicionary[this.props.selectedLanguage]["grain"]]:
            foodGroups["grain"].co2,
          [dicionary[this.props.selectedLanguage]["produce"]]:
            foodGroups["produce"].co2,
          [dicionary[this.props.selectedLanguage]["sugar_fat"]]:
            foodGroups["sugar_fat"].co2,
          [dicionary[this.props.selectedLanguage]["other"]]:
            foodGroups["other"].co2,
          amt: 2210,
        },
        this.state.countryData
          ? {
              name: countryName,
              [dicionary[this.props.selectedLanguage]["meat"]]: this.state
                .countryData["meat"].co2,
              [dicionary[this.props.selectedLanguage]["dairy_eggs"]]: this.state
                .countryData["dairy_eggs"].co2,
              [dicionary[this.props.selectedLanguage]["grain"]]: this.state
                .countryData["grain"].co2,
              [dicionary[this.props.selectedLanguage]["produce"]]: this.state
                .countryData["produce"].co2,
              [dicionary[this.props.selectedLanguage]["sugar_fat"]]: this.state
                .countryData["sugar_fat"].co2,
              [dicionary[this.props.selectedLanguage]["other"]]: this.state
                .countryData["other"].co2,
              amt: 2210,
            }
          : null,

        this.state.sameGroupSurveyCO2 && this.state.numberOfSurveysSameGroup > 0
          ? {
              name:
                nameOfSameGroupBar +
                " " +
                this.state.numberOfSurveysSameGroup +
                " *",
              [dicionary[this.props.selectedLanguage]["meat"]]: this.state
                .sameGroupSurveyCO2["meat"],
              [dicionary[this.props.selectedLanguage]["dairy_eggs"]]: this.state
                .sameGroupSurveyCO2["dairy_eggs"],
              [dicionary[this.props.selectedLanguage]["grain"]]: this.state
                .sameGroupSurveyCO2["grain"],
              [dicionary[this.props.selectedLanguage]["produce"]]: this.state
                .sameGroupSurveyCO2["produce"],
              [dicionary[this.props.selectedLanguage]["sugar_fat"]]: this.state
                .sameGroupSurveyCO2["sugar_fat"],
              [dicionary[this.props.selectedLanguage]["other"]]: this.state
                .sameGroupSurveyCO2["other"],
              amt: 2210,
            }
          : null,
        {
          name: dicionary[this.props.selectedLanguage]["world"] + "(**)",
          [dicionary[this.props.selectedLanguage]["sugar_fat"]]: 0.41,
          [dicionary[this.props.selectedLanguage]["grain"]]: 0.97,
          [dicionary[this.props.selectedLanguage]["dairy_eggs"]]: 0.79,
          [dicionary[this.props.selectedLanguage]["other"]]: 0.39,
          [dicionary[this.props.selectedLanguage]["produce"]]: 1.64,
          [dicionary[this.props.selectedLanguage]["meat"]]: 0.48,
          amt: 2210,
        },
      ];
      // console.log(
      //   "this.state.countryData,this.state.sameGroupSurveyCO2",
      //   this.state.countryData,
      //   this.state.sameGroupSurveyCO2,
      //   "graphData",
      //   graphData
      // );

      graphData = graphData.filter((item) => !!item);

      // this.setState({ graphData });

      this.setState({ graphData });
      // return graphData;
      //}
    }
  }
  // calculateGraphCO2Values() {
  //   let foodGroups = this.props.generalSurveyData.foodGroups;
  //   let totalCO2AllGroups = 0;
  //   let graphData;
  //   if (this.props.generalSurveyData.foodGroups) {
  //   Object.keys(foodGroups).forEach(key => {
  //     if (!isNaN(foodGroups[key].co2)) {
  //       foodGroups[key].co2 = parseFloat(foodGroups[key].co2).toFixed(2);
  //     }
  //   });

  //   graphData = [
  //     {
  //       name: "You",
  //       meat: foodGroups["meat"].co2,
  //       dairy_eggs: foodGroups["dairy_eggs"].co2,
  //       grain: foodGroups["grain"].co2,
  //       produce: foodGroups["produce"].co2,
  //       sugar_fat: foodGroups["sugar_fat"].co2,
  //       other: foodGroups["other"].co2,
  //       amt: 2210
  //     },
  //     this.state.countryData
  //       ? {
  //           name: this.getAnswerFromProp("3A_1_country"),
  //           meat: this.state.countryData["meat"].co2,
  //           dairy_eggs: this.state.countryData["dairy_eggs"].co2,
  //           grain: this.state.countryData["grain"].co2,
  //           produce: this.state.countryData["produce"].co2,
  //           sugar_fat: this.state.countryData["sugar_fat"].co2,
  //           other: this.state.countryData["other"].co2,
  //           amt: 2210
  //         }
  //       : null,

  //     this.state.sameGroupSurveyCO2
  //       ? {
  //           name: this.getAnswerFromProp("5_1_groupMember"),
  //           meat: this.state.sameGroupSurveyCO2["meat"].co2,
  //           dairy_eggs: this.state.sameGroupSurveyCO2["dairy_eggs"].co2,
  //           grain: this.state.sameGroupSurveyCO2["grain"].co2,
  //           produce: this.state.sameGroupSurveyCO2["produce"].co2,
  //           sugar_fat: this.state.sameGroupSurveyCO2["sugar_fat"].co2,
  //           other: this.state.sameGroupSurveyCO2["other"].co2,
  //           amt: 2210
  //         }
  //       : null,
  //     {
  //       name: "World",
  //       sugar_fat: 0.41,
  //       grain: 0.97,
  //       dairy_eggs: 0.79,
  //       other: 0.39,
  //       produce: 1.64,
  //       meat: 0.48,
  //       amt: 2210
  //     }
  //   ];

  //    graphData = graphData.filter(item => !!item);
  //   console.log("graphData", graphData);
  //   // this.setState({ graphData });
  //   return graphData;
  //   }
  // }

  render() {
    // console.log(
    //   "this.state.sameGroupSurveyCO2, this.state.countryData",
    //   this.state.sameGroupSurveyCO2,
    //   this.state.countryData
    // );

    return (
      <>
        <div
          style={{
            //backgroundImage: "url(" + bgimage + ")",
            background: "#F6F6F6",
            padding: this.props.matches.large ? "60px" : "80px 20px",
            minHeight: "100vh",
            height: "auto",
            width: "100%",
            textAlign:
              this.props.selectedLanguage === "ar_AE" ? "right" : "left",
            direction: this.props.selectedLanguage === "ar_AE" ? "rtl" : null,
          }}
        >
          <div
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "20px",
              lineHeight: "45px",
              color: "#000",
            }}
          >
            {
              dicionary[this.props.selectedLanguage][
                "Thank you for taking the survey!"
              ]
            }
          </div>
          <div
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              paddingTop: "40px",
              lineHeight: "50px",
              color: "#000000",
              paddingLeft: "40px",
            }}
          >
            {
              dicionary[this.props.selectedLanguage][
                "Your food consumption is responsible for"
              ]
            }
            <strong> {this.getTotalCo2AllGroups()} </strong>{" "}
            {dicionary[this.props.selectedLanguage]["tCO2eq/year"]}
          </div>
          {/* <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "15px",
              lineHeight: "29px",
              color: "#000000",
              paddingTop: "40px",
              paddingLeft: "80px"
            }}
          >
            The average carbon emissions per capita in your country (all
            sectors) is ___.
          </div>{" "} */}
          <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "15px",
              lineHeight: "29px",
              color: "#000000",
              paddingTop: "30px",
              paddingLeft: this.props.matches.large ? "80px" : "5px",
            }}
          >
            {
              dicionary[this.props.selectedLanguage][
                "Each leg of a trans-Atlantic flight"
              ]
            }
          </div>
          {/* <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "15px",
              lineHeight: "29px",
              color: "#000000",
              paddingTop: "30px",
              paddingLeft: "80px"
            }}
          >
            You can select among the following diets to compare your carbon
            foodprint to:
          </div> */}
          <div
            style={{
              display: "flex",
              //direction: "row",
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
              paddingTop: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              {this.props.generalSurveyData.foodGroups ? (
                <BarChart
                  width={this.props.matches.large ? 500 : 400}
                  height={300}
                  data={this.state.graphData}
                  stackOffset="sign"
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  {/* <Legend /> */}
                  <ReferenceLine y={0} stroke="#000" />
                  <Bar
                    dataKey={
                      "" + [dicionary[this.props.selectedLanguage]["meat"]]
                    }
                    fill="#8A0000"
                    stackId="stack"
                  />
                  <Bar
                    dataKey={
                      "" +
                      [dicionary[this.props.selectedLanguage]["dairy_eggs"]]
                    }
                    fill="#F97432"
                    stackId="stack"
                  />
                  <Bar
                    dataKey={
                      "" + [dicionary[this.props.selectedLanguage]["grain"]]
                    }
                    fill="#FFCC01"
                    stackId="stack"
                  />
                  <Bar
                    dataKey={
                      "" + [dicionary[this.props.selectedLanguage]["produce"]]
                    }
                    fill="#25523B"
                    stackId="stack"
                  />
                  <Bar
                    dataKey={
                      "" + [dicionary[this.props.selectedLanguage]["sugar_fat"]]
                    }
                    fill="#2C43B8"
                    stackId="stack"
                  />
                  <Bar
                    dataKey={
                      "" + [dicionary[this.props.selectedLanguage]["other"]]
                    }
                    fill="#502989"
                    stackId="stack"
                  />
                </BarChart>
              ) : null}
              {this.state.sameGroupSurveyCO2 &&
              this.state.numberOfSurveysSameGroup > 0 ? (
                <div
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "start",
                    textAlign: "left",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "100",
                    fontSize: "13px",
                    lineHeight: "29px",
                    color: "#000000",
                    paddingTop: "20px",
                    paddingLeft: this.props.matches.large ? "60px" : "5px",
                  }}
                >
                  {
                    dicionary[this.props.selectedLanguage][
                      "Number of people from your group who took the survey so far."
                    ]
                  }
                  <br /> {dicionary[this.props.selectedLanguage]["Group:"]}
                  {this.getAnswerFromProp("5_1_groupMember")} <br />{" "}
                  {dicionary[this.props.selectedLanguage]["Respondents:"]}{" "}
                  {this.state.numberOfSurveysSameGroup}
                </div>
              ) : null}
              <div
                style={{
                  justifyContent: "flex-start",
                  alignItems: "start",
                  textAlign: "left",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "100",
                  fontSize: "13px",
                  lineHeight: "29px",
                  color: "#000000",
                  paddingTop: "20px",
                  paddingLeft: this.props.matches.large ? "60px" : "5px",
                }}
              >
                <strong>**</strong>{" "}
                {
                  dicionary[this.props.selectedLanguage][
                    "**Carbon content of food supply (kgCO2eq/cap/year), estimated from food supplied for human consumption in 2017"
                  ]
                }{" "}
                <br />
                {
                  dicionary[this.props.selectedLanguage][
                    "(Food Balance Sheets, FAOSTAT, United Nations)"
                  ]
                }
              </div>
            </div>

            {this.props.matches.large ? (
              this.props.selectedLanguage === "us_US" ? (
                <div
                  // clear button
                  style={{
                    display: "flex",
                    backgroundImage: "url(" + graphLegend + ")",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                    //height: "auto",
                    width: "123px",
                  }}
                ></div>
              ) : (
                <div
                  // clear button
                  style={{
                    display: "flex",
                    backgroundImage: "url(" + graphLegend_AR + ")",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                    //height: "auto",
                    width: "123px",
                  }}
                ></div>
              )
            ) : null}
          </div>

          {/* <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "25px",
              lineHeight: "29px",
              color: "#000000"
            }}
          >
            Please use the box below to share any comments.
          </div> */}
          <div
            style={{
              display: "flex",
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <div
                style={{
                  textAlign: "center",
                  width: "188px",
                  height: "63px",
                  background: "rgb(255, 255, 255)",
                  border: "#46645B",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderRadius: "4px",
                  color: "rgb(70, 100, 91)",
                  textTransform: "uppercase",
                  padding: "20px",
                  margin: "5px",
                }}
              >
                {dicionary[this.props.selectedLanguage]["Back Home"]}
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default Survey_Page_final;
