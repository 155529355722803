export const ingredient_list = [
  {
    "Food item": "Salad",
    Type: "(beef)",
    Composition:
      "beef (30%); greens, vegetables and starchy roots (65%); vegetable oils (5%)"
  },
  {
    "Food item": "Salad",
    Type: "(other)",
    Composition:
      "other meat (30%); greens, vegetables and starchy roots (65%); vegetable oils (5%)"
  },
  {
    "Food item": "Salad",
    Type: "(fish/seafood)",
    Composition:
      "fish/seafood (30%); greens, vegetables and starchy roots (65%); vegetable oils (5%)"
  },
  {
    "Food item": "Salad",
    Type: "(vegetarian)",
    Composition:
      "greens, vegetables and starchy roots (80%); animal fats (10%); vegetable oils (5%); pulses (5%)"
  },
  {
    "Food item": "Salad",
    Type: "(vegan)",
    Composition:
      "greens, vegetables and starchy roots (65%); vegetable oils (5%); oil crops (15%); pulses (15%)"
  },
  {
    "Food item": "Sandwich",
    Type: "(beef)",
    Composition:
      "bread (35%); beef (30%); greens, vegetables and starchy roots (25%); animal fats (5%); vegatable oils (5%)"
  },
  {
    "Food item": "Sandwich",
    Type: "(other)",
    Composition:
      "bread (35%); other meat (30%); greens, vegetables and starchy roots (25%); animal fats (5%); vegatable oils (5%)"
  },
  {
    "Food item": "Sandwich",
    Type: "(fish/seafood)",
    Composition:
      "bread (35%); fish/seafood (30%); greens, vegetables and starchy roots (25%); animal fats (5%); vegatable oils (5%)"
  },
  {
    "Food item": "Sandwich",
    Type: "(vegetarian)",
    Composition:
      "bread (35%); greens, vegetables and starchy roots (50%); animal fats (10%); vegatable oils (5%)"
  },
  {
    "Food item": "Sandwich",
    Type: "(vegan)",
    Composition:
      "bread (35%); greens, vegetables and starchy roots (50%); vegatable oils (5%); oil crops (10%)"
  },
  {
    "Food item": "Pizza",
    Type: "(beef)",
    Composition:
      "dough (20%); beef (20%); vegetables (30%); animal fats (25%); vegatable oils (5%)"
  },
  {
    "Food item": "Pizza",
    Type: "(other)",
    Composition:
      "dough (20%); other meat (20%); vegetables (30%); animal fats (25%); vegatable oils (5%)"
  },
  {
    "Food item": "Pizza",
    Type: "(fish/seafood)",
    Composition:
      "dough (20%); fish/seafood (20%); vegetables (30%); animal fats (25%); vegatable oils (5%)"
  },
  {
    "Food item": "Pizza",
    Type: "(vegetarian)",
    Composition:
      "dough (20%); vegetables (50%); animal fats (25%); vegatable oils (5%)"
  },
  {
    "Food item": "Pizza",
    Type: "(vegan)",
    Composition:
      "dough (20%); vegetables (60%); vegatable oils (5%); oil crops (10%); miscellaneous (5%)"
  },
  {
    "Food item": "Soup",
    Type: "(beef)",
    Composition:
      "beef (10%); vegetables and starchy roots (30%); animal fats (7.5%); vegetable oils (2.5%); pulses (5%); water (45%)"
  },
  {
    "Food item": "Soup",
    Type: "(other)",
    Composition:
      "other meat (10%); vegetables and starchy roots (30%); animal fats (7.5%); vegetable oils (2.5%); pulses (5%); water (45%)"
  },
  {
    "Food item": "Soup",
    Type: "(fish/seafood)",
    Composition:
      "fish/seafood (10%); vegetables and starchy roots (30%); animal fats (7.5%); vegetable oils (2.5%); pulses (5%); water (45%)"
  },
  {
    "Food item": "Soup",
    Type: "(vegetarian)",
    Composition:
      "vegetables and starchy roots (40%); animal fats (7.5%); vegetable oils (2.5%); pulses (5%); water (45%)"
  },
  {
    "Food item": "Soup",
    Type: "(vegan)",
    Composition:
      "vegetables and starchy roots (30%); vegetable oils (2.5%); pulses (10%); miscellaneous (12.5); water (45%)"
  }
];
