import React, { Component } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import hero_text from "./img/new/hero_text.svg";
//import "./styles/Hero_img_temp.css";
/*
import worldMap from "./img/world-low-resolution.svg";
import hero_text from "./img/hero_text.svg";
import hero_footer from "./img/hero_footer.svg";*/
import read_more from "./img/new/home/readMore.svg";
import "./styles/App.css";
import "./styles/header.css";
import "./styles/Hero.css";

//import foot_left from "./img/footer_logo_left.svg";

export default class Hero extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <div className="hero-container">
        <div
          className="new-hero"
          style={{
            backgroundImage: "url(" + hero_text + ") "
          }}
        />
        <br />
        <img
          onClick={() => this.props.scrollTo("assmt-title")}
          alt={"readmore"}
          className="readmore_button_hero"
          src={read_more}
        />{" "}
        <div className="hero_bg_image" />
      </div>
    );
  }
}
