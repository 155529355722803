import React, { Component } from "react";
import { Row, Col, Modal } from "antd";
import { useEffect } from "react";

import { HashRouter, Route, Link, Switch, useLocation } from "react-router-dom";
//import { slide as Menu } from "react-burger-menu";
import "./styles/App.css";
import "./styles/Hero.css";
import "./styles/header.css";
//import Hero from "./Hero";
import Media from "react-media";
import logo_FP from "./img/new/logo_new.svg";
import button_getFoodPrints from "./img/new/menu_button_getFoodPrints.svg";
import Home from "./pages/Home";
import About from "./pages/About";
import OurWork from "./pages/OurWork";
import GetYourFoodPrint from "./pages/GetYourFoodPrint";
import Harvest from "./pages/Harvest";
import Publications from "./pages/Publications";
import Teaching from "./pages/Teaching";
import Survey from "./pages/Survey";

import { slide as Menu } from "react-burger-menu";
import SurveyPage1 from "./components/survey/Survey_Page_1";
import SurveyPage3 from "./components/survey/Survey_Page_3";
import SurveyPage2plates from "./components/survey/Survey_Page_2_plates";
import SurveyPageFinal from "./components/survey/Survey_Page_final";
import SurveyIntro from "./pages/SurveyIntro";
var hamburguerStyles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    left: "36px",
    top: "36px"
  },
  bmBurgerBars: {
    background: "#373a47",
    color: "white",
    mixBlendMode: "difference"
  },

  bmBurgerBarsHover: {
    background: "#a90000"
  },
  bmCrossButton: {
    height: "24px",
    width: "24px"
  },
  bmCross: {
    background: "#bdc3c7"
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%"
  },
  bmMenu: {
    background: "#373a47",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em"
  },
  bmMorphShape: {
    fill: "#373a47"
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em"
  },
  bmItem: {
    display: "inline-block"
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)"
  }
};
class App extends Component {
  constructor(props) {
    super(props);

    this.scrollTo = this.scrollTo.bind(this);
    this.addAnswerToSurvey = this.addAnswerToSurvey.bind(this);
    this.changePlateUX = this.changePlateUX.bind(this);
    // this.showModalPlates = this.showModalPlates.bind(this);
    this.setGeneralSurveyData = this.setGeneralSurveyData.bind(this);
    this.saveFoodtrayFullInfo = this.saveFoodtrayFullInfo.bind(this);
    this.saveWeekdiferenceOrAnyday = this.saveWeekdiferenceOrAnyday.bind(this);
    this.getFoodDayState = this.getFoodDayState.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.state = {
      platesVisible: false,
      answers: [],
      plateData: {},
      generalSurveyData: {},
      foodTrayFullInfo: {},
      foodDay: {},
      selectedLanguage: "us_US",
      totalCo2: {}, //separated by meals (object)
      totalCalories: {}, //separated by meals (object)
      drinksChosen: { breakfast: [], lunch: [], dinner: [], snacks: [] }
    };
  }

  render() {
    console.log(
      "appjs - this.state.foodTrayFullInfo:",
      this.state.foodTrayFullInfo
    );
    const NotFound = () => <div>Not found</div>;

    return (
      <>
        <HashRouter>
          <ScrollToTop />
          <div
            style={{
              // position: "absolute",
              // width: "100%",
              // height: "auto",
              backgroundColor: "#fff",
              display: "flex",

              //overflowY: "scroll",
              justifyContent: "center" /* align horizontal */,
              alignItems: "center" /* align vertical */
            }}
          >
            <div
              style={{
                maxWidth: "1200px",
                height: "auto",
                width: "100%"
                //overflowY: "scroll"
                //position: "absolute",
                // left: 0,
                // right: 0,
                // margin: "auto"
              }}
            >
              <Media
                queries={{
                  small: "(max-width: 599px)",
                  medium: "(min-width: 600px) and (max-width: 999px)",
                  large: "(min-width: 1000px)"
                }}
              >
                {matches => (
                  <>
                    {matches.small && this.renderHamburguerHeader()}
                    {matches.medium && this.renderHamburguerHeader()}
                    {matches.large && this.renderLargeHeader()}
                    <Switch>
                      <Route exact path="/" component={Home} />
                      <Route path="/about" component={About} />
                      <Route path="/ourwork" component={OurWork} />
                      <Route path="/harvest" component={Harvest} />
                      <Route path="/publications" component={Publications} />
                      <Route path="/teaching" component={Teaching} />
                      <Route path="/survey">
                        <Survey
                          matches={matches}
                          selectedLanguage={this.state.selectedLanguage}
                          setLanguage={this.setLanguage}
                        />
                      </Route>
                      <Route path="/survey_intro">
                        <SurveyIntro />
                      </Route>
                      <Route path="/survey_page_1">
                        <SurveyPage1
                          addAnswerToSurvey={this.addAnswerToSurvey}
                          answers={this.state.answers}
                          matches={matches}
                          selectedLanguage={this.state.selectedLanguage}
                          setLanguage={() => this.setLanguage}
                        />
                      </Route>

                      <Route path="/survey_page_3">
                        <SurveyPage3
                          addAnswerToSurvey={this.addAnswerToSurvey}
                          answers={this.state.answers}
                          setGeneralSurveyData={this.setGeneralSurveyData}
                          generalSurveyData={this.state.generalSurveyData}
                          matches={matches}
                          selectedLanguage={this.state.selectedLanguage}
                          //selectedLanguage={"ar_AE"} //change me!!
                          setLanguage={() => this.setLanguage}
                        />
                      </Route>
                      <Route path="/Survey_Page_2_plates">
                        <SurveyPage2plates
                          addAnswerToSurvey={this.addAnswerToSurvey}
                          answers={this.state.answers}
                          plateData={this.state.plateData}
                          changePlateUX={this.changePlateUX}
                          generalSurveyData={this.state.generalSurveyData}
                          setGeneralSurveyData={this.setGeneralSurveyData}
                          saveFoodtrayFullInfo={this.saveFoodtrayFullInfo}
                          foodTrayFullInfo={this.state.foodTrayFullInfo}
                          mealTotals={{
                            co2: this.state.totalCo2,
                            calories: this.state.totalCalories
                          }}
                          saveWeekdiferenceOrAnyday={
                            this.saveWeekdiferenceOrAnyday
                          }
                          getFoodDayState={this.getFoodDayState}
                          matches={matches}
                          selectedLanguage={this.state.selectedLanguage}
                          // selectedLanguage={"ar_AE"} //change me!!
                          setLanguage={() => this.selectedLanguage}
                          drinksChosen={this.state.drinksChosen}
                        />
                      </Route>
                      <Route path="/survey_page_final">
                        <SurveyPageFinal
                          addAnswerToSurvey={this.addAnswerToSurvey}
                          answers={this.state.answers}
                          generalSurveyData={this.state.generalSurveyData}
                          setGeneralSurveyData={this.setGeneralSurveyData}
                          matches={matches}
                          selectedLanguage={this.state.selectedLanguage}
                          // selectedLanguage={"ar_AE"} //change me!!
                          setLanguage={() => this.selectedLanguage}
                        />
                      </Route>
                      <Route
                        path="/getyourfoodprint"
                        component={GetYourFoodPrint}
                      />
                    </Switch>
                  </>
                )}
              </Media>
            </div>
          </div>
        </HashRouter>
      </>
    );
  }
  setGeneralSurveyData(field, fieldData) {
    let generalSurveyData = this.state.generalSurveyData;
    generalSurveyData[field] = fieldData;
    this.setState({ generalSurveyData });
  }

  setLanguage(selectedLanguage) {
    this.setState({ selectedLanguage });
  }
  saveFoodtrayFullInfo(
    meal,
    foodTrayFullInfo,
    totalCo2,
    totalCalories,
    drinksChosen
  ) {
    console.log(
      "app.js -saveFoodtrayFullInfo - meal,foodTrayFullInfo",
      meal,
      foodTrayFullInfo,
      "totalCo2, totalCalories,drinksChosen",
      totalCo2,
      totalCalories,
      drinksChosen
    );

    let tempFoodTray = this.state.foodTrayFullInfo;
    tempFoodTray[meal] = foodTrayFullInfo;

    let totalCo2_temp = this.state.totalCo2;
    totalCo2_temp[meal] = totalCo2;

    let totalCalories_temp = this.state.totalCalories;
    totalCalories_temp[meal] = totalCalories;

    let drinksChosen_temp = this.state.drinksChosen;
    drinksChosen_temp[meal] = drinksChosen;

    this.setState({
      foodTrayFullInfo: tempFoodTray,
      totalCalories: totalCalories_temp,
      totalCo2: totalCo2_temp,
      drinksChosen: drinksChosen_temp //not separated by meals
    });
  }
  saveWeekdiferenceOrAnyday(meal, dayChoosen) {
    console.log(
      "app.js -saveWeekdiferenceOrAnyday - meal,dayChoosen",
      meal,
      dayChoosen
    );

    let foodDay = this.state.foodDay;
    foodDay[meal] = dayChoosen;

    this.setState({ foodDay });
  }
  getFoodDayState(meal) {
    return this.state.foodDay[meal];
  }

  changePlateUX(meal, plate) {
    let UXplate = this.state.plateData;
    UXplate[meal] = plate;

    this.setState({ plateData: UXplate });
  }
  addAnswerToSurvey(answer) {
    let currentSurvey = this.state.answers;
    let oldAnswer = currentSurvey.find(
      answersOnSurvey =>
        answer.survey_question === answersOnSurvey.survey_question
    );
    if (oldAnswer) {
      let indexOfanswer = currentSurvey.indexOf(oldAnswer);

      currentSurvey[indexOfanswer] = answer; //replace by new Answer
    } else {
      currentSurvey.push(answer); //add new naswer
    }
    this.setState({ answers: currentSurvey });

    //  console.log("AFTER CHANGES STATE: ", this.state.answers);
  }

  scrollTo(item) {
    //  console.log(item);
    window.scrollTo({
      top: document.getElementById(item).getBoundingClientRect().bottom,
      behavior: "smooth"
    });
  }

  // showModalPlates() {
  //   return (
  //     <Modal
  //       wrapClassName={"plateModal"}
  //       // width="100%"
  //       style={{ top: "5vh", textAlign: "center", maxWidth: "1200px" }}
  //       buttonProps={{ size: "small" }}
  //       bodyStyle={{
  //         minHeight: "90vh",
  //         // width: "100%",
  //         maxWidth: "1200px"
  //       }}
  //       visible={this.state.platesVisible}
  //       // onOk={this.goBack}
  //       onCancel={() => this.setState({ platesVisible: false })}
  //       // footer={null}
  //     >
  //       <Plates />
  //     </Modal>
  //   );
  // }

  renderHamburguerHeader() {
    return (
      <Menu styles={hamburguerStyles}>
        <a id="home" className="hamburguer-item" href="/">
          Home
        </a>
        <a id="about" className="hamburguer-item " href="/#/about">
          About
        </a>
        <a id="ourwork" className="hamburguer-item ourwork" href="/#/ourwork">
          Our Work
        </a>
        <a
          id="publications"
          className="hamburguer-item ourwork"
          href="/#/publications"
        >
          Publications
        </a>
        <a id="teaching" className="hamburguer-item ourwork" href="/#/teaching">
          Teaching
        </a>
        <a id="survey" className="hamburguer-item" href="/#/survey">
          SURVEY
        </a>
        <a id="HARVEST" className="hamburguer-item" href="/#/harvest">
          HARVEST
        </a>

        <a
          id="Getyourfoodprints"
          className="hamburguer-item"
          href="/#/getyourfoodprint"
        >
          <img
            alt={"button_getFoodPrints"}
            src={button_getFoodPrints}
            className="menu-items"
          />
        </a>
      </Menu>
    );
  }
  renderLargeHeader() {
    return (
      <Row type="flex" justify="center">
        <div id="header-container">
          <Col lg={{ span: 10, offset: 0 }}>
            <Link to="/">
              <img
                className="FP_logo"
                alt={"logo"}
                src={logo_FP}
                style={{ paddingLeft: "20px" }}
              />
            </Link>
          </Col>
          <Col lg={{ span: 2, offset: 0 }}>
            <Link to="/about">
              <div className="menu-items">About</div>
            </Link>
          </Col>

          <Col lg={{ span: 4, offset: 0 }}>
            <div className="dropdown menu-items">
              <span style={{ color: "black", textAlign: "start" }}>
                Our Work
              </span>
              <div className="dropdown-content">
                <Link to="/ourwork">
                  <div className="menu-items">Our Work</div>
                </Link>
                <Link to="/survey">
                  <div className="menu-items">Survey</div>
                </Link>
                <Link to="/publications">
                  <div className="menu-items">Publications</div>
                </Link>
                <Link to="/teaching">
                  <div className="menu-items">Teaching</div>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={{ span: 3, offset: 0 }}>
            <Link to="/harvest">
              <div className="menu-items">HARVEST</div>
            </Link>
          </Col>

          <Col xs={{ span: 15, offset: 6 }} lg={{ span: 3, offset: 0 }}>
            <Link to="/getyourfoodprint">
              <img
                alt={"button_getFoodPrints"}
                src={button_getFoodPrints}
                className="menu-items"
              />
            </Link>
          </Col>
        </div>
      </Row>
    );
  }
}

export default App;

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
