export const dicionary = {
  us_US: {
    hello: "hello",
    go_to_survey: "Go to survey",
    //buttons
    previous: "Previous",
    next: "Next",
    //survey_page1
    whats_the_carbon_of_your_food: "What’s the carbon content of your food?",
    page1from3: " Page 1/3",
    seccion1_about_you: "Section 1. About you",
    cancel: "Cancel",
    partners: "Partners",
    //1
    question1_title: "1. What is your gender?",
    question1_option1: "Male",
    question1_option2: "Female",
    //2
    question2_title: "2. What is your age?",
    question2_under18: "Under 18",
    question2_option1: "19-24",
    question2_option2: "25-34",
    question2_option3: "35-49",
    question2_option4: "50-64",
    question2_option5: "65 +",
    //3
    question3_title:
      "3. Where do you live? (This information is used to determine the carbon content of food in your region)",
    select_country: "Select Country",
    region: "Select Region",

    //new 4!
    question_exercice_title: "4. How often do you exercise?",
    question_exercice_option1: "Once a week or less",
    question_exercice_option2: "1-3 times a week",
    question_exercice_option3: "At least 5 times a week",

    // former 4
    question4_title:
      "5. What is the highest level of education you have completed (or are currently completing)?",
    question4_option1: "No qualification",
    question4_option2: "High school",
    question4_option3: "Bachelors degree",
    question4_option4: "Postgraduate degreee",
    //5
    question5_title: "6. Are you a member of any of these groups?",
    question5_option1: "MIT",
    question5_option2: "KACST",
    question5_option3: "SOLEMMA SYMPOSIUM",
    question5_option4: "None of them",

    //page2 survey - (meals)
    page2from3: "Page 2/3",
    seccion2_your_plate: "Section 2. What’s on your plate?",
    what_do_you_eat: "What do you eat on a typical day?",
    this_section_is:
      " This section is for you to draw average meals that best represent what you eat on a typical day.",
    total: "Total",
    title_breakfast: "7. BREAKFAST",
    title_lunch: "8. LUNCH",
    title_dinner: "9. DINNER",
    title_snacks: "10. SNACKS",
    fill_the_plate: "Fill the Plate!",
    kcal_day: "kcal/day",
    kgCO2eq_day: "kgCO2eq/day",
    dont_have_this_meal: "I don't have this meal",
    calories: "Calories",
    carbon: "Carbon",

    //Foodtray
    help: "Help",
    build_more_options_for: "Build more options for this meal.",
    delete_everything_and: "Delete everything and start from scratch.",

    "What’s in the prepared foods": " What’s in the prepared foods?",
    close: "Close",
    please_fill_correctly: "Please fill the meal information correctly",
    "Please answer the question before":
      "Please answer the question before selecting a drink",
    "Please click or drag a food item into the tray":
      "Please click or drag a food item into the tray",

    drinks: "DRINKS",
    drink_size: "Drink Size:",
    size_XS: "XS",
    size_S: "S",
    size_M: "M",
    size_L: "L",
    size_XL: "XL",
    reference_size: "Reference size",
    on_what_days_do_you: "On what days do you typically eat this meal?",
    switch_to_weekend: "Switch to weekend",
    switch_to_weekday: "Switch to weekday",
    add_alternative_meal: "Add Alternative Meal",
    clear_all: "Clear all",
    //group names
    meat: "Meat",
    dairy_eggs: "Dairy & eggs",
    grain: "Grain",
    produce: "Produce",
    sugar_fat: "Sugar & fat",
    other: "Other/vegan",
    // //group names FOR GRAPHS
    // group_meat: "meat",
    // group_dairy_eggs: "dairy_eggs",
    // group_grain: "grain",
    // group_produce: "produce",
    // group_sugar_fat: "sugar_fat",
    // group_other: "other",
    // buttons
    typical_weekday: "Typical week day",
    typical_weekend: " Typical weekend",
    or: "OR",
    anyday: "Any Day",
    please_select_the_food_and_drag_it:
      "Please select food item below and drag it, or left-click one the tray to add.(Or double click!).",
    your_meal: " Your meal",
    //all food & drinks
    "meat (beef)": "meat (beef)",
    "meat (other)": "meat (other)",
    "fish/seafood": "fish/seafood",
    "salad (beef)": "salad (beef)",
    "salad (other)": "salad (other)",
    "salad (fish/seafood)": "salad (fish/seafood)",
    "sandwich (beef)": "sandwich (beef)",
    "sandwich (other)": "sandwich (other)",
    "sandwich (fish/seafood)": "sandwich (fish/seafood)",
    "pizza (beef)": "pizza (beef)",
    "pizza (other)": "pizza (other)",
    "pizza (fish/seafood)": "pizza (fish/seafood)",
    butter: "butter",
    cheese: "cheese",
    "eggs (boiled)": "eggs (boiled)",
    "eggs (scrambled)": "eggs (scrambled)",
    "ice cream (with dairy)": "ice cream (with dairy)",
    "ice cream (vegan)": "ice cream (vegan)",
    "milk (animal-based)": "milk (animal-based)",
    "milk (plant-based)": "milk (plant-based)",
    "Yogurt (animal-based)": "Yogurt (animal-based)",
    "Yogurt (plant-based)": "Yogurt (plant-based)",
    bread: "bread",
    cereals: "cereals",
    granola: "granola",
    muesli: "muesli",
    noodles: "noodles",
    pancake: "pancake",
    pasta: "pasta",
    rice: "rice",
    seitan: "seitan",
    "french fries": "french fries",
    fruit: "fruit",
    mushrooms: "mushrooms",
    nuts: "nuts",
    potatoes: "potatoes",
    "potato chips": "potato chips",
    vegetables: "vegetables",
    "salad (vegetarian)": "salad (vegetarian)",
    "sandwich (vegetarian)": "sandwich (vegetarian)",
    "pizza (vegetarian)": "pizza (vegetarian)",
    cookies: "cookies",
    croissant: "croissant",
    donut: "donut",
    jelly: "jelly",
    muffin: "muffin",
    pastry: "pastry",
    "peanut butter": "peanut butter",
    chocolate: "chocolate",
    pulses: "pulses",
    "tempeh/tofu": "tempeh/tofu",
    "salad (vegan)": "salad (vegan)",
    "sandwich (vegan)": "sandwich (vegan)",
    "pizza (vegan)": "pizza (vegan)",
    "Cocoa (no milk)": "Cocoa (no milk)",
    "Cocoa + milk (animal-based)": "Cocoa + milk (animal-based)",
    "Cocoa + milk (plant-based)": "Cocoa + milk (plant-based)",
    "Coffee (no milk)": "Coffee (no milk)",
    "Coffee + milk (animal-based)": "Coffee + milk (animal-based)",
    "Coffee + milk (plant-based)": "Coffee + milk (plant-based)",
    "Fruit juice": "Fruit juice",
    "Milk (animal-based)": "Milk (animal-based)",
    "Milk (plant-based)": "Milk (plant-based)",
    "Milk smoothie (animal-based)": "Milk smoothie (animal-based)",
    "Milk smoothie (plant-based)": "Milk smoothie (plant-based)",
    "Drinking yogurt (animal-based)": "Drinking yogurt (animal-based)",
    "Drinking yogurt (plant-based)": "Drinking yogurt (plant-based)",
    "Soft drink": "Soft drink",
    Tea: "Tea",
    "Tea + milk (animal-based)": "Tea + milk (animal-based)",
    "Tea + milk (plant-based)": "Tea + milk (plant-based)",
    Water: "Water",
    Beer: "Beer",
    Wine: "Wine",
    "soup (beef)": "soup (beef)",
    "soup (other)": "soup (other)",
    "soup (fish/seafood)": "soup (fish/seafood)",
    "soup (vegetarian)": "soup (vegetarian)",
    "soup (vegan)": "soup (vegan)",

    //page3
    page3_of3: "Page 3/3",
    seccion3_whats_your: "Section 3. What are your shopping preferences?",
    //11
    where_do_you_mostly_buy_your_food: "11. Where do you mostly buy your food?",
    supermarket: "Supermarket",
    farmers_market: "Farmers’ market",
    online: "Online",
    other: "Other",
    i_dont_buy_food: "I don´t buy food",
    //12
    "Which one of these products would you buy":
      "12. Which one of these products would you buy?",
    //12.1
    "Imported or national": "12.1. Imported or national?",
    "(same price)": "(same price)",
    "(premium)": "(premium)",
    "Imported $ 1.00": "Imported $ 1.00",
    "National $ 1.00": "National $ 1.00",

    "National $ 1.50": "National $ 1.50",
    //12.2
    "Non-organic or organic": "12.2. Non-organic or organic?",
    "Non-organic $ 1.00": "Non-organic $ 1.00",
    "Organic $ 1.00": "Organic $ 1.00",
    "Organic $ 1.50": "Organic $ 1.50",

    "There are new innovative ways of growing...":
      "13. There are new innovative ways of growing food, and some (such as hydroponics) have been shown to have a lower environmental impact.",
    "Which one of these products would you buy":
      "Which one of these products would you buy?",
    "Regular or low-carbon?": "Regular or low-carbon?",

    "Regular $ 1.00": "Regular $ 1.00",
    "Low-Carbon $ 1.00": "Low-Carbon $ 1.00",
    "Low-Carbon $ 1.50": "Low-Carbon $ 1.50",
    Finish: "Finish",
    "Thank you for taking the survey!": "Thank you for taking the survey!",

    "Your food consumption is responsible for":
      "Your food consumption is responsible for:",

    "tCO2eq/year": "tCO2eq/year",

    "Each leg of a trans-Atlantic flight":
      "Each leg of a trans-Atlantic flight emits about one ton of carbon dioxide per passenger. ",
    "**Carbon content of food supply (kgCO2eq/cap/year), estimated from food supplied for human consumption in 2017":
      "**Carbon content of food supply (kgCO2eq/cap/year), estimated from food supplied for human consumption in 2017",

    "(Food Balance Sheets, FAOSTAT, United Nations)":
      "(Food Balance Sheets, FAOSTAT, United Nations)",

    "Number of people from your group who took the survey so far.":
      "* Number of people from your group who took the survey so far.",

    "Group:": "Group:",

    "Respondents:": "Respondents:",

    "Back Home": "Back Home",

    "Share of daily recommended calories:":
      "Share of daily recommended calories:",

    "Recommended for you:": "Recommended for you:",

    KCal: "KCal",
    you: "You",
    world: "World",
    question_mark: "?"
  },
  ar_AE: {
    //General
    ok: "حسنًا",
    hello: "مرحبا",
    go_to_survey: "اذهب إلى الاستبيان",
    //buttons
    previous: "السابق",
    next: "التالى",
    //survey_page1
    whats_the_carbon_of_your_food: "ما هو المتحوى الكربوني في طعامك؟",
    partners: "شركاء",

    page1from3: "صفحة ١/٣",
    seccion1_about_you: "١.أخبرنا عنك",
    cancel: "إلغاء",
    //1
    question1_title: "١- الجنس:",
    question1_option1: "ذكر",
    question1_option2: "أنثى",

    //2
    question2_title: "٢- أفضل فئة عمرية تصفك",
    question2_under18: "تحت ١٨",
    question2_option1: "بين ١٩-٢٤ سنة",
    question2_option2: "بين ٢٥-٣٤ سنة.",
    question2_option3: "بين ٣٥-٤٩ سنة.",
    question2_option4: "بين ٥٠-٦٤ سنة.",
    question2_option5: " أكبر من ٦٥ سنة.",

    //3
    question3_title:
      " ٣- أين تسكن؟ - هذه المعلومة ستستخدم لتحديد نسبة ثاني أكسيد الكربون المنبعث من العادات الغذائية في منطقتك",
    select_country: "الدولة:",
    region: "المنطقة:",

    //new 4!
    question_exercice_title: "٤- كم مرة تمارس الرياضة؟",
    question_exercice_option1: "مرة في الاسبوع او اقل",
    question_exercice_option2: "مرة إلى ثلاث مرات في الأسبوع",
    question_exercice_option3: "خمس مرات في الأسبوع على الأقل",

    //former 4
    question4_title: "٥-مؤهلك العلمي -أعلى مؤهل علمي أكملته أو لازلت تكمله",
    question4_option1: "أقل من شهادة الثانوية",
    question4_option2: "ثانوي",
    question4_option3: "بكالوريوس",
    question4_option4: "دراسات عليا",

    //5
    question5_title: "٦-هل أنت عضو في أي من هذه المجموعات؟",
    question5_option1: "MIT",
    question5_option2: "KACST",
    question5_option3: "SOLEMMA SYMPOSIUM",
    question5_option4: "لا احد منهم",

    //page2 survey - (meals)
    page2from3: "صفحة ٢/٣",
    seccion2_your_plate: "٢. أخبرنا عن طبق غذائك",
    what_do_you_eat: " مالذي تأكله في يومك الطبيعي؟",
    this_section_is:
      " هذا القسم يتطلب منك اختيار متوسط الوجبات التي تمثل استهلاكك في يومك الطبيعي ",
    total: " المجموع",
    title_breakfast: "٧.الفطور",
    title_lunch: "٨.الغداء",
    title_dinner: "٩.العشاء",
    title_snacks: "	١٠.جبات خفيفة",
    fill_the_plate: "!املأ الطبق",
    kcal_day: "سعرة حرارية في اليوم",
    kgCO2eq_day: "كجم من ثاني أكسيد الكربون في اليوم",
    dont_have_this_meal: "لا آكل هذه الوجبة",
    calories: "السعرات الحرارية",
    carbon: "ثاني أكسيد الكربون",

    //Foodtray
    help: "مساعدة",
    build_more_options_for: "قم ببناء المزيد من الخيارات لهذه الوجبة",
    delete_everything_and: "احذف كل شيء وابدأ من الصفر",
    "What’s in the prepared foods": "ماذا يوجد في الأطعمة المحضرة؟",
    close: "أغلق",
    please_fill_correctly: "يرجى ملء معلومات الوجبة بشكل صحيح",
    "Please answer the question before":
      "يرجى الإجابة على السؤال قبل اختيار المشروبات",

    "Please click or drag a food item into the tray":
      "يرجى النقر أو سحب عنصر طعام إلى اللوحة",

    drinks: " مشروبات",
    drink_size: "شرب الحجم",
    size_XS: "صغير جدًا",
    size_S: "صغير",
    size_M: "وسط",
    size_L: "كبير",
    size_XL: "كبير جدًا",
    reference_size: "الحجم مقارنةً بالصورة",
    on_what_days_do_you: " في أي يوم تأكل هذه الوجبة بالعادة؟ ",
    switch_to_weekend: "عطلة نهاية الاسبوع",
    switch_to_weekday: "يوم الأسبوع",
    add_alternative_meal: "إضافة وجبة",
    clear_all: "مسح الكل",
    //group names
    meat: "لحوم",
    dairy_eggs: "البيض/الألبان",
    grain: "(القمح (الحبوب",
    produce: "محصول",
    sugar_fat: "السكر/الدهون",
    other: "أخرى",
    // buttons
    typical_weekday: " في أيام الأسبوع",
    typical_weekend: " عطلة نهاية الأسبوع",
    or: "أو",
    anyday: "في أي يوم",
    please_select_the_food_and_drag_it:
      "الرجاء اختيار الصنف الغذائي و سحبه للطبق، أو قم بالضغط عليه و من ثم قم بالضغط على الطبق لإضافته",
    your_meal: " وجبتك",
    //all food & drinks
    "meat (beef)": "لحم (بقر) ",
    "meat (other)": " لحم (أخرى)",
    "fish/seafood": "سمك/مأكولات بحرية",
    "salad (beef)": "سلطة (لحم بقر)",
    "salad (other)": "سلطة (أخرى)",
    "salad (fish/seafood)": "سلطة )سمك/مأكولات بحرية)",
    "sandwich (beef)": " شطيرة (ساندويتش) (لحم بقر)",
    "sandwich (other)": "شطيرة (ساندويتش) (أخرى)",
    "sandwich (fish/seafood)": "شطيرة (ساندويتش) (سمك/مأكولات بحرية)",

    "pizza (beef)": "(بيتزا (لحم بقر",
    "pizza (other)": "(بيتزا (لحوم أخرى",
    "pizza (fish/seafood)": " (بيتزا (أسماك / مأكولات بحرية",

    butter: "الزبدة",
    cheese: "الجبنة",
    "eggs (boiled)": "البيض (مسلوق)",
    "eggs (scrambled)": "البيض( المخفوق)",
    "ice cream (with dairy)": "الأيس كريم مع منتجات الحليب",
    "ice cream (vegan)": "الأيس كريم النباتي",
    "milk (animal-based)": "الحليب (الحيواني)",
    "milk (plant-based)": "الحليب (النباتي)",
    "Yogurt (animal-based)": "الزبادي (الحيواني)",
    "Yogurt (plant-based)": "الزبادي (النباتي)",

    bread: "الخبز",
    cereals: "الخبز الذرة",
    granola: "الشوفان (جرنولة)",
    muesli: "ميوزلي",
    noodles: "المعكرونة",
    pancake: "بانكيك",
    pasta: "باستا",
    rice: "الأرز",
    seitan: "سيطان",

    "french fries": "البطاطس المقلية",
    fruit: "فواكة",
    mushrooms: "مشروم (فطر)",
    nuts: "الجوز",
    potatoes: "البطاطس",
    "potato chips": "شرائح البطاطس",
    vegetables: "خضار",
    "salad (vegetarian)": "سلطة (خضار)",
    "sandwich (vegetarian)": "ساندويتش (شطيرة خضار)",
    "pizza (vegetarian)": "بيتزا (خضار)=",

    cookies: "كوكيز",
    croissant: "كرواسون",
    donut: "الدونات",
    jelly: "الجيلي",
    muffin: "فطيرة",
    pastry: "معجنات",
    "peanut butter": "زبدة الفول السوداني",
    chocolate: "الشوكلاتة",
    pulses: "الفول",
    "tempeh/tofu": "الفول",
    "salad (vegan)": "سلطة(نباتيين)",
    "sandwich (vegan)": "ساندويتش (شطيرة نباتيين)",
    "pizza (vegan)": "بيتزا (نباتيين)",

    "Cocoa (no milk)": "(كاكاو (من غير حليب",
    "Cocoa + milk (animal-based)": "( كاكاو مع الحليب (النباتي ",
    "Cocoa + milk (plant-based)": " كاكاو مع الحليب (النباتي) ",
    "Coffee (no milk)": " القهوة (بدون حليب)",
    "Coffee + milk (animal-based)": "القهوة +حليب (حيواني) ",
    "Coffee + milk (plant-based)": " القهوة +حليب (نباتي)",
    "Fruit juice": "عصير فواكة",
    "Milk (animal-based)": "حليب (حيواني) ",
    "Milk (plant-based)": "حليب (نباتي) ",
    "Milk smoothie (animal-based)": "حليب سموثي (حيواني) ",
    "Milk smoothie (plant-based)": "حليب سموثي (نباتي)",
    "Soft drink": "مشروب غازي",
    Tea: "الشاي",
    "Tea + milk (animal-based)": " شاي +حليب (حيواني)",
    "Tea + milk (plant-based)": "شاي +حليب (نباتي) ",
    "Drinking yogurt (animal-based)": "شرب اللبن - حيواني",
    "Drinking yogurt (plant-based)": "شرب اللبن - نباتي",

    Water: "ماء",
    Beer: "----",
    Wine: "----",

    "soup (beef)": "(حساء (لحم البقر",
    "soup (other)": " (حساء (لحوم أخرى",
    "soup (fish/seafood)": " (حساء (أسماك / مأكولات بحرية",
    "soup (vegetarian)": " (حساء (خضار",
    "soup (vegan)": "(حساء (نباتي",
    //page3
    page3_of3: "صفحة ٣/٣",
    seccion3_whats_your: "٣ . أخبرنا عن عادات تسوقك الغذائى",
    //11
    where_do_you_mostly_buy_your_food: "١١- أين تشتري طعامك في الغالب؟",
    supermarket: "سوق مركزي",
    farmers_market: "سوق الخضار",
    online: "عبر الانترنت",
    other: "آخر",
    i_dont_buy_food: "لا أشتري منتجات الطعام",
    //12
    "Which one of these products would you buy":
      "١٢- أي من هذه المنتجات ستشتريها؟",

    //12.1
    "Imported or national": "مستورد او وطني",

    "(same price)": "نفس السعر",
    "(premium)": "جودة عالية",

    "Imported $ 1.00": "مستورد $ 1.00",
    "National $ 1.00": "وطني $ 1.00",

    "National $ 1.50": "وطني $ 1.50",
    //12.2
    "Non-organic or organic": "غير عضوي أو عضوي",
    "Non-organic $ 1.00": "غير عضوي $ 1.00",
    "Organic $ 1.00": "عضوي $ 1.00",
    "Organic $ 1.50": "عضوي $ 1.50",

    "There are new innovative ways of growing...":
      " ١٣- هناك طرق جديدة مبتكرة للزراعة، وقد ثبت أن بعضًا منها - مثل الزراعة في المحميات المائية - له تأثير بيئي أقل الطرق الأخرى",
    "Which one of these products would you buy":
      " أي من هذه المنتجات ستشتريها؟",
    "Regular or low-carbon?": "منخفض الكربون أو عادي",

    "Regular $ 1.00": "عادي $ 1.00",
    "Low-Carbon $ 1.00": "منخفض الكربون $ 1.00",
    "Low-Carbon $ 1.50": "منخفض الكربون $ 1.50",
    Finish: "إنهاء",

    "Thank you for taking the survey!": "شكرًا لمشاركتكم في الاستبيان!",

    "Your food consumption is responsible for":
      "استهلاكك الغذائي ينتج عنه ما يعادل:",

    "tCO2eq/year": "طن من ثاني أكسيد الكربون في السنة",

    "Each leg of a trans-Atlantic flight":
      "ينتج عن عبور طائرة ركاب للمحيط الأطلسي ما يقارب طن من انبعاثات ثاني أكسيد الكربون للراكب الواحد.",
    "**Carbon content of food supply (kgCO2eq/cap/year), estimated from food supplied for human consumption in 2017":
      "محتوى الكربون من الإمدادات الغذائية (كيلوغرام ثاني أكسيد الكربون للفرد في السنة) ، حسب تقدير منظمة الأغذية و الزراعة للأمم المتحدة في عام 2017",

    "(Food Balance Sheets, FAOSTAT, United Nations)":
      "(التوازن الغائي، احصائيات منظمة الأغذية والزراعة للأمم المتحدة)",

    "Number of people from your group who took the survey so far.":
      " عدد الأشخاص من مجموعتك الذين أجروا الاستطلاع حتى الآن*",

    "Group:": "مجموعة:",

    "Respondents:": "المجيبين: ",

    "Back Home": "العودة إلى الصفحة الرئيسية",

    "Share of daily recommended calories:":
      "حصة السعرات الحرارية الموصى بها يوميا",

    "Recommended for you:": "موصى به لك:",

    KCal: "سعرات حراريه",

    you: "أنت",
    world: "العالمية",
    question_mark: "؟"
  }
};

//  export function dicionary(){

//  }
