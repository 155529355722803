import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  MenuItem,
  Radio,
  RadioGroup
} from "@material-ui/core/";
import TextField from "@material-ui/core/TextField";
import { Col, Menu, Row, Select } from "antd";
import React, { Component } from "react";
import defaultJSON from "./../../data/defaultJSON.json";
import icon_plus from "./../../img/icon_plus.svg";
import "./../../styles/header.css";
//import "./styles/App.css";
import "./../../styles/mainForm.css";

const SubMenu = Menu.SubMenu;
const Option = Select.Option;
const crops_available = [];

let defaultJSON_modified = defaultJSON;
let crops_array = defaultJSON.Vegetables.map(crop => crop.Name);

crops_array.forEach(crop => {
  //adding the prefixes
  if (crop === "RedCrops") crop = "R_" + crop;
  if (crop === "OrangeCrops") crop = "O_" + crop;
  if (crop === "LightGreenCrops") crop = "LG_" + crop;
  if (crop === "DarkGreenCrops") crop = "DG_" + crop;

  crops_available.push(<Option key={crop}>{crop}</Option>);
});

const styles = theme => ({
  menuItem: {
    "&:focus": {
      backgroundColor: "#f00",
      "& $primary, & $icon": {
        color: "#000"
      }
    }
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 300
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },

  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  group: {
    margin: `${theme.spacing.unit}px 0`
  },
  button: {
    margin: theme.spacing.unit,
    color: "#009688"
  }
});

const current_supply_chain = [
  {
    label: "Per capita supply",
    placeholder: "What is the current demand for the crop?",
    unit: "kg/cap/year",
    jsonField: "PerCapitaSupply"
  },
  {
    label: "Food miles",
    placeholder: "What distance does it travel to reach the city?",
    unit: "km",
    jsonField: "FoodMiles"
  },
  {
    label: "Embodied energy",
    placeholder: "How much energy is used to produce it?",
    unit: "kWh/kg",
    jsonField: "PerKgEnergyUse"
  },
  {
    label: "Embodied water",
    placeholder: "How much water is used to produce it?",
    unit: "L/kg",
    jsonField: "WaterUseImported"
  },

  {
    label: "Food waste",
    placeholder: "What share is lost accross the supply chain?",
    unit: "%",
    jsonField: "FoodWaste"
  },
  // {
  //   label: "Production cost",
  //   placeholder: "What is its average production cost?",
  //   unit: "$/kg",
  //   jsonField: "PerKgProductionCost"
  // },
  {
    label: "Average retail price",
    placeholder: "What is its current retail price?",
    unit: "$/kg",
    jsonField: "AverageRetailPrice"
  }

  // {
  //   label: "Transportation mode",
  //   placeholder: ". Truck",
  //   unit: "kg/cap/year",
  //   jsonField: "TransportationMode"
  // }
];

const crop_productivity = [
  // {
  //   label: "Optimal yield (Controlled-Environment Agriculture)",
  //   placeholder: 11.4,
  //   unit: "kg/m2/year",
  //   jsonField: "OptimalYield"
  // },
  {
    label: "Occupancy coefficient",
    placeholder: 0.6,
    unit: "m",
    jsonField: "OccupancyCoefficient"
  },
  {
    label: "Root depth",
    placeholder: 0.1,
    unit: "kg/cap/year",
    jsonField: "RootDepth"
  },
  {
    label: "Shoot height",
    placeholder: 0.6,
    unit: "m",
    jsonField: "ShootHeight"
  },
  {
    label: "Tray interval",
    placeholder: 1.25,
    unit: "index",
    jsonField: "TrayInterval"
  },
  {
    label: "Light Use Efficiency (LUE)",
    placeholder: 0.0073,
    unit: "kg/mol/m2",
    jsonField: "LightUseEfficiency"
  },
  {
    label: "Water Use Efficiency (WUE)",
    placeholder: 0.0627,
    unit: "kg/L/m2",
    jsonField: "WaterUseEfficiency"
  },
  {
    label: "Harvest index",
    placeholder: 0.33,
    unit: "index",
    jsonField: "HarvestIndex"
  },
  {
    label: "Crop losses",
    placeholder: 0.05,
    unit: "index",
    jsonField: "CropLosses"
  }
];

const urban_farming_costs = [
  // {
  //   label: "Farm lifespan",
  //   placeholder: "What is the lifespan of your urban farm?",
  //   unit: "years",
  //   jsonField: "FarmLifespan"
  // },
  // {
  //   label: "Rent (if farm located in existing building)",
  //   placeholder: "What is the cost of rent in your city?",
  //   unit: "$/m2/year",
  //   jsonField: "CostOfRent"
  // },

  // {
  //   label: "Structure (If farm is a new construction) ",
  //   placeholder: "What is the cost of your farm’s structure?",
  //   unit: "$/m2/year",
  //   jsonField: "CostOfStructure"
  // },
  // {
  //   label: "Farm equipment (maintenance and replacement)",
  //   placeholder: "What is the cost of your equipment?",
  //   unit: "$/m2/year",
  //   jsonField: "CostOfEquipment"
  // },
  // {
  //   label: "Hydroponic supplies",
  //   placeholder: "What are the costs of your seeds and nutrients?",
  //   unit: "$/m2/year",
  //   jsonField: "CostOfSupplies"
  // },
  {
    label: "Labor",
    placeholder: "What is the wage per worker?",
    unit: "$/year",
    jsonField: "CostOfLabor"
  },
  // {
  //   label: "Energy rate",
  //   placeholder: "What is the price of electricity?",
  //   unit: "$/kWh",
  //   jsonField: "EnergyRate"
  // },
  {
    label: "Water rate",
    placeholder: "What is the price of water?",
    unit: "$/L",
    jsonField: "WaterRate"
  }
  // {
  //   label: "Energy - Emission factor",
  //   placeholder: "What is the emission factor of electricity",
  //   unit: "kgCO2eq/kWh",
  //   jsonField: "EFEnergy"
  // },

  // {
  //   label: "Hydroponic premium",
  //   placeholder: "What is the premium for CEA produce?",
  //   unit: "%",
  //   jsonField: "BiaPremium"
  // }
];
const emission_factors = [
  {
    label: "Embodied energy - Emission factor",
    placeholder: "What is the emission factor of energy at origin?",
    unit: "kgCO2eq/kWh",
    jsonField: "EFEnergyOrigin"
  },
  {
    label: "Embodied water - Emission factor",
    placeholder: "What is the emission factor of water at origin?",
    unit: "kgCO2eq/kWh",
    jsonField: "EFWaterOrigin"
  },
  {
    label: "Water - Emission Factor",
    placeholder: "What is the emission factor of water?",
    unit: "kgCO2eq/L",
    jsonField: "EFWater"
  }
];

//const emission_factors = [

const mutateObjectProperty = (prop, value, obj) =>
  obj.constructor === Object &&
  Object.keys(obj).forEach(key => {
    if (key === prop) obj[key] = value;
    mutateObjectProperty(prop, value, obj[key]);
  });

export default class MainForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cropEditing: null,
      selectedCrops: [],
      //selectedCrops: ['BokChoy', 'Broccoli'], // TEST!!
      // cropEditing: 'BokChoy',// TEST!!
      finalJSON: { Name: "", Vegetables: [] },
      error: false, //something missing or wrong
      missingValues: true,
      missingValuesText: ""
    };
  }

  getJSON() {
    var data = new Blob([JSON.stringify(this.state.finalJSON, null, 2)], {
      type: "text/json"
    });
    var csvURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement("a");
    document.body.appendChild(tempLink);
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "jsonFile.fpr");
    tempLink.target = "_self";
    tempLink.click();
  }

  handleChange(value) {
    console.log(value);

    this.setState({ selectedCrops: value });
  }

  clearAllFields() {
    //clear all fields (VERY UGLY)
    this.setState({ clearFields: true });

    this.setState({ clearFields: false });
  }

  handleCropChange(event) {
    console.log(event);

    this.setState({ cropEditing: event.target.value.split("_").pop() });

    this.missingValues();
  }

  deletedOneCrop(value) {
    // if (value === this.state.cropEditing)

    this.setState({ cropEditing: null }); //a crop was deleted. This will avoid editing a non-existing crop (or wrongly change som existing one)    console.log("deletedOneCrop",value);

    this.missingValues();
  }

  missingValues() {
    let isMissing = false;
    this.setState({ missingValuesText: "" });
    // console.log(isMissing);

    this.state.finalJSON.Vegetables.map(vegetableData => {
      console.log("checking", vegetableData.Name);

      Object.keys(vegetableData).forEach(key => {
        console.log("checking", key, vegetableData.Name);

        if (vegetableData[key] === "") {
          console.log("missing " + key + " in " + vegetableData.Name);
          this.setState({
            missingValuesText:
              this.state.missingValuesText +
              "\nmissing " +
              key +
              " in " +
              vegetableData.Name
          });
          isMissing = true;
        }
      });
    });
    console.log(isMissing);

    if (this.state.finalJSON.Name === "") isMissing = true;
    if (this.state.cropEditing == null) isMissing = true;

    this.setState({
      missingValues: isMissing
    });

    return isMissing;
  }

  changedVegetableData(event, jsonField, type) {
    this.clearAllFields();

    console.log(type);

    let valueToParse = event.target.value;
    let valueParsed;

    if (type === "number") {
      if (
        valueToParse[valueToParse.length - 1] === "," ||
        valueToParse[valueToParse.length - 1] === "." ||
        valueToParse[valueToParse.length - 1] === "0" ||
        valueToParse === ""
      ) {
        // console.log(valueToParse);
        valueParsed = event.target.value;
        //console.log(valueParsed);
      } else {
        let checkifANumber = parseFloat(valueToParse.replace(/,/, "."));
        //console.log(checkifANumber);
        if (isNaN(checkifANumber)) {
          return;
        } else valueParsed = checkifANumber;
      }
    } else valueParsed = event.target.value;

    let tempJSON = defaultJSON;
    let indexOfVegetable = tempJSON.Vegetables.findIndex(
      vegetable => vegetable.Name === this.state.cropEditing
    );

    let jsonToWrite = this.state.finalJSON;
    let indexOfVegetable_FinalJSON = jsonToWrite.Vegetables.findIndex(
      vegetable => vegetable.Name === this.state.cropEditing
    );

    //console.log(jsonToWrite)
    //console.log(indexOfVegetable)

    if (indexOfVegetable_FinalJSON === -1) {
      //console.log("indexOfVegetable_FinalJSON is -1");
      //console.log(jsonToWrite)
      jsonToWrite.Vegetables.push(tempJSON.Vegetables[indexOfVegetable]); //add a new crop if non-existent

      mutateObjectProperty(
        jsonField,
        valueParsed,
        jsonToWrite.Vegetables[jsonToWrite.Vegetables.length - 1]
      );
    } else {
      mutateObjectProperty(
        jsonField,
        valueParsed,
        jsonToWrite.Vegetables[indexOfVegetable_FinalJSON]
      );
    }

    this.setState({ finalJSON: jsonToWrite });
    //console.log(this.state.finalJSON);
    this.missingValues(); //checking of all values are filled
  }

  /*
  valueSavedOnSpecificCropField(jsonField) {
    //are we editing crops? (and what crop)
    if (this.state.cropEditing)
      if (
        this.state.finalJSON.Vegetables[
          this.state.finalJSON.Vegetables.findIndex(
            vegetable => vegetable.Name === this.state.cropEditing
          )
        ][jsonField]
      ) {
        //there is already some data on this specific field?

        return this.state.finalJSON.Vegetables[
          this.state.finalJSON.Vegetables.findIndex(
            vegetable => vegetable.Name === this.state.cropEditing
          )
        ][jsonField];
      } else return "";
  }
*/
  renderInput(label, placeholder, unit, jsonField, forceEnable) {
    //There are two special cases. one for the dropdown on transportation, and another for the City name (diferent JSON path)

    //special case for "transportation mode field"
    if (jsonField === "TransportationMode")
      return (
        <Select
          multiple
          //value={this.state.name}
          placeholder={"Freight mode"}
          onChange={value => {
            //console.log(value);

            let dummyjson = { target: { value } }; //simulating the json object read on the function below
            //console.log(dummyjson);
            this.changedVegetableData(dummyjson, jsonField, "string");
          }}
          style={{ width: 300, marginTop: "24px", marginBottom: "24px" }}
          input={<Input id="select-multiple" />}
          // MenuProps={MenuProps}
        >
          <MenuItem key={"Road"} value={"Road"}>
            {"Road"}
          </MenuItem>
          <MenuItem key={"Rail"} value={"Rail"}>
            {"Rail"}
          </MenuItem>
          <MenuItem key={"Ship"} value={"Ship"}>
            {"Ship"}
          </MenuItem>
          <MenuItem key={"Air"} value={"Air"}>
            {"Air"}
          </MenuItem>
        </Select>
      );
    else if (label === "City") {
      return (
        <TextField
          disabled={forceEnable ? false : this.state.cropEditing ? false : true}
          key={label}
          id="standard-name"
          label={label}
          style={{}}
          className={styles.textField}
          InputLabelProps={{
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: 300,
              color: "black",
              fontSize: "12px"
              //
            }
          }}
          InputProps={{
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: 300,
              //color: "#009688",

              fontSize: "12px"
            }
          }}
          onChange={event => {
            //specific for change the city name
            let tempJSON = this.state.finalJSON;

            mutateObjectProperty("Name", event.target.value, tempJSON);
            this.setState({ finalJSON: tempJSON });
            this.missingValues();

            //  console.log(this.state.finalJSON);
          }}
          margin="normal"
          //autoFocus={true}

          placeholder={"Type the city name"}
        />
      );
    } //for every all fields
    else
      return (
        <div>
          <TextField
            disabled={
              forceEnable ? false : this.state.cropEditing ? false : true
            }
            key={label}
            id="standard-name"
            label={label}
            className={styles.textField}
            InputLabelProps={{
              shrink: this.state.cropEditing ? true : false,
              style: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: 300,
                color: "black",
                fontSize: "12px"
                //
              }
            }}
            InputProps={{
              style: {
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: 300,
                //color: "#009688",

                fontSize: "12px"
              }
            }}
            onChange={event => {
              this.changedVegetableData(event, jsonField, "number");

              //console.log(this.state.finalJSON);
            }}
            margin="normal"
            //autoFocus={true}
            value={
              this.state.clearFields //damm HACK TODO
                ? ""
                : this.state.cropEditing
                ? defaultJSON_modified.Vegetables[
                    defaultJSON.Vegetables.findIndex(
                      vegetable => vegetable.Name === this.state.cropEditing
                    )
                  ][jsonField]
                : null
            }
            defaultValue={
              this.state.cropEditing
                ? defaultJSON.Vegetables[
                    defaultJSON.Vegetables.findIndex(
                      vegetable => vegetable.Name === this.state.cropEditing
                    )
                  ][jsonField]
                : null
            }
            placeholder={placeholder.toString()}
            error={
              isNaN(
                this.state.cropEditing
                  ? defaultJSON.Vegetables[
                      defaultJSON.Vegetables.findIndex(
                        vegetable => vegetable.Name === this.state.cropEditing
                      )
                    ][jsonField]
                  : null
              )
                ? true
                : false
            }
          />
          {isNaN(
            this.state.cropEditing
              ? defaultJSON.Vegetables[
                  defaultJSON.Vegetables.findIndex(
                    vegetable => vegetable.Name === this.state.cropEditing
                  )
                ][jsonField]
              : null
          ) ? (
            <div style={{ fontSize: "9px", color: "red" }}>
              {"This field is required, it must be a number"}
            </div>
          ) : null}
          <div style={{ fontSize: "9px", textAlign: "end", width: "300px" }}>
            {unit}
          </div>
        </div>
      );
  }

  render() {
    //let crops_array = defaultJSON.Vegetables.map((crop)=>crop.Name)
    // console.log(crops_available, this.state.finalJSON);

    return (
      <div className="main-form-background" id="main_form">
        <div className="main-form-title-small">BUILD YOUR URBAN FOODPRINT</div>
        <div className="main-form-title-big">FOODprint generator</div>
        <Row>
          <Col xs={{ span: 20, offset: 1 }} lg={{ span: 6, offset: 1 }}>
            {this.renderInput(
              "City",
              "",
              "",
              "",
              true /*forceEnable always enable*/
            )}
          </Col>

          <Col xs={{ span: 20, offset: 1 }} lg={{ span: 6, offset: 1 }}>
            {this.state.cropEditing ? (
              <div className="main-form-editing-crop">
                Editing {this.state.cropEditing}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 20, offset: 1 }} lg={{ span: 6, offset: 1 }}>
            <div className="main-form-section-titles">CROPS</div>

            <div
              style={{ display: "flex", flexDirection: "row", width: "300px" }}
            >
              <img
                className="icon_plus"
                //onClick={() => this.toggleLeft()}
                src={icon_plus}
                alt="addcrop"
                onClick={() => this.nameDiv.focus()}
              />
              <Select
                ref={div => {
                  this.nameDiv = div;
                }}
                //open={this.state.open}

                mode="multiple"
                style={{ width: "100%", paddingTop: "10px" }}
                placeholder="Add Crops"
                defaultValue={[]} //["Broccoli", "BokChoy"]
                onChange={this.handleChange.bind(this)}
                onDeselect={this.deletedOneCrop.bind(this)}
              >
                {crops_available}
              </Select>
            </div>
            <br />

            <FormControl component="fieldset" className={styles.formControl}>
              <FormLabel component="legend">{""}</FormLabel>
              <RadioGroup
                aria-label=""
                name="gender1"
                className={styles.group}
                value={this.state.cropEditing}
                onChange={this.handleCropChange.bind(this)}
              >
                {this.state.selectedCrops.map(item => (
                  <FormControlLabel
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <br />

            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#009688",
                width: 300,
                marginBottom: "24px"
              }}
              onClick={() => {
                window.location.reload();
                this.setState({
                  //cropEditing: null,
                  //selectedCrops: [],

                  finalJSON: { Name: "", Vegetables: [] }
                });
              }}
            >
              RESET FORM
            </Button>
            <div
              style={{
                fontSize: "12px",
                paddingTop: "24px",
                paddingBottom: "12px"
              }}
            >
              Please fill in all fields correctly
              <br /> to download the JSON file:
            </div>
            <Button
              disabled={this.state.missingValues}
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#009688", width: "300px" }}
              onClick={() => this.getJSON()}
            >
              GET JSON FILE
            </Button>
          </Col>
          <Col xs={{ span: 20, offset: 1 }} lg={{ span: 6, offset: 1 }}>
            <div className="main-form-section-titles">CURRENT SUPPLY CHAIN</div>
            {current_supply_chain.map(item => {
              return this.renderInput(
                item.label,
                item.placeholder,
                item.unit,
                item.jsonField
              );
            })}
            <div className="main-form-section-titles">CROP PRODUCTIVITY</div>
            {crop_productivity.map(item => {
              return this.renderInput(
                item.label,
                item.placeholder,
                item.unit,
                item.jsonField
              );
            })}
          </Col>
          <Col xs={{ span: 20, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <div className="main-form-section-titles">URBAN FARMING</div>

            {urban_farming_costs.map(item => {
              return this.renderInput(
                item.label,
                item.placeholder,
                item.unit,
                item.jsonField
              );
            })}
          </Col>
          <Col xs={{ span: 20, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <div className="main-form-section-titles">EMISSION FACTORS</div>

            {emission_factors.map(item => {
              return this.renderInput(
                item.label,
                item.placeholder,
                item.unit,
                item.jsonField
              );
            })}
          </Col>
        </Row>
      </div>
    );
  }
}
