import React, { Component } from "react";

import Footer from "../components/footer";
//import SurveyHome from "../components/survey/SurveyHome";
import surveyIntroBg from "../img/surveyintro_background.svg";
import organizations_survey_intro from "../img/survey_organizacoes.png";
import { Link } from "react-router-dom";
import { dicionary } from "../dicionary";
class SurveyIntro extends Component {
  constructor(props) {
    super(props);

    // this.state = { selectedLanguage: "arabish" };
  }

  render() {
    return (
      <>
        <div>
          <div className="full_about">Ongoing </div>
          <div
            style={{
              height: "100vh",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundImage: "url(" + surveyIntroBg + ")",
              padding: "50px"
            }}
          >
            <div
              style={{
                width: "100%",
                //height: "300px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end"
              }}
            >
              <div
                style={{
                  height: "70px",
                  width: "250px",
                  backgroundSize: "cover",
                  backgroundImage: "url(" + organizations_survey_intro + ")"
                }}
              ></div>
            </div>
            <div
              style={{
                height: "auto",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column"
              }}
            >
              <div
                style={{
                  fontFamily: "Quicksand",
                  fontStyle: "normal",
                  fontWeight: "800",
                  fontSize: "29px",
                  lineHeight: "45px",
                  textAlign: "center",
                  color: "#46645B",
                  paddingBottom: "30px"
                }}
              >
                Survey{" "}
              </div>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "21px",
                  textAlign: "center",
                  color: "#46645B",
                  paddingBottom: "30px"
                }}
              >
                MEASURE THE CARBON CONTENT OF YOUR FOOD
              </div>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "300",
                  fontSize: "16px",
                  lineHeight: "177.04%",
                  paddingBottom: "30px",
                  color: "#000"
                }}
              >
                We are currently focusing on expanding our food analysis
                capabilities (HARVEST plugin) from plants to the overall diet
                providing a holistic analysis urban food systems and suggesting
                sustainable alternatives. <br /> <br />
                <strong>
                  Join us in the effort of collecting data on food consumption
                  habits by answering our 15-min survey and find out about the
                  carbon content of what you eat!
                </strong>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Link to="/survey">
                <div
                  // onClick={() => this.getFoodFromDB()}

                  style={{
                    background: "#46645B",
                    width: "160px",
                    height: "50px",
                    textTransform: "uppercase",
                    padding: "15px",
                    marginTop: "50px",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "15px",
                    lineHeight: "18px",
                    color: "#F6F6F6",
                    borderRadius: "5px"
                  }}
                >
                  Go to survey
                </div>
              </Link>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default SurveyIntro;
