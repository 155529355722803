import React, { Component } from "react";

import introGraph from "./images/intro_graph_pic.svg";
import introGraph_AE from "./images/intro_graph_pic_AE.svg";
import mitLogo from "./images/question_pictures/mit_logo.svg";
import cces_logo from "./images/question_pictures/cces_logo.svg";
import kpf_logo from "./images/question_pictures/kpf_logo.svg";

import { getFood, sendSurvey } from "../../network/foodAndSurvey";
import { dicionary } from "../../dicionary";
import { Link } from "react-router-dom";
import { Select, Row, Col } from "antd";
import "./survey_css.css";
const { Option } = Select;

var testSurvey = {
  date: Date.now(),
  answers: [
    {
      description: "test",
      survey_question: 1,
      answer: "australia",
      type: "single_answer",
      groupAnswer: null,
      othersValue: null,
      plates: null
    },

    {
      description: "test2",
      survey_question: 2,
      answer: "plates",
      type: "single_answer",
      groupAnswer: null,
      othersValue: null,
      plates: [
        {
          meal: "breakfast",
          foodQuantity: 3,
          portions: [{ idFood: "idbread", percentage: 100 }],
          drinks: [
            {
              idFood: "idCoke",
              drinkSize: 2
            }
          ]
        },
        {
          meal: "lunch",
          foodQuantity: 3,
          portions: [{ idFood: "idbread", percentage: 100 }],
          drinks: [
            {
              idFood: "idCoke",
              drinkSize: 2
            }
          ]
        },
        {
          meal: "dinner",
          foodQuantity: 3,
          portions: [{ idFood: "idbread", percentage: 100 }],
          drinks: [
            {
              idFood: "idCoke",
              drinkSize: 2
            }
          ]
        }
      ]
    }
  ]
};

class SurveyHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLanguage: "us_US",
      //selectedLanguage: "ar_AE",

      filledSurvey: null
    };
  }
  // async getFoodFromDB() {
  //   let dbFood = await getFood();
  //   this.setState({ food: dbFood });
  //   console.log("food from DD: ", JSON.stringify(dbFood, null, 2));
  // }

  componentDidMount() {
    // this.setState({ selectedLanguage: "ar_AE" });
    // this.props.setLanguage("ar_AE");

    if (this.props.selectedLanguage) {
      this.setState({ selectedLanguage: this.props.selectedLanguage });
    } else {
      this.setState({ selectedLanguage: "us_US" });
    }
  }
  async sendTestSurvey() {
    if (this.state.food) {
      //hacking values
      testSurvey.answers[1].plates[0].portions[0].idFood = this.state.food[0]._id; //bread
      testSurvey.answers[1].plates[0].drinks[0].idFood = this.state.food[
        this.state.food.length - 1
      ]._id; //chá

      testSurvey.answers[1].plates[1].portions[0].idFood = this.state.food[0]._id; //bread
      testSurvey.answers[1].plates[1].drinks[0].idFood = this.state.food[
        this.state.food.length - 1
      ]._id; //chá

      testSurvey.answers[1].plates[2].portions[0].idFood = this.state.food[0]._id; //bread
      testSurvey.answers[1].plates[2].drinks[0].idFood = this.state.food[
        this.state.food.length - 1
      ]._id; //chá

      console.log("sending: ", testSurvey);
      console.log(await sendSurvey(testSurvey));
    }
  }

  languageChanged(value) {
    console.log(`selected ${value}`);
    this.setState({ selectedLanguage: value });
    this.props.setLanguage(value);
  }

  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#F6F6F6",
            //backgroundImage: "url(" + bgimage + ")",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
            height: "auto",
            //width: "100vw",
            padding: "50px"
          }}
        >
          <Row type="flex" justify="space-between">
            <Col xs={{ span: 23 }} lg={{ span: 15 }}>
              <div
                style={{
                  fontFamily: "Quicksand",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "30px",
                  color: "#46645B",
                  paddingTop: "20px",
                  paddingBottom: "20px"
                }}
              >
                {
                  dicionary[this.state.selectedLanguage]
                    .whats_the_carbon_of_your_food
                }
              </div>
            </Col>
            <Col xs={{ span: 23 }} lg={{ span: 5 }}>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "15px",
                  lineHeight: "14px",
                  color: "#46645B"
                  //marginTop: "40px"
                }}
              >
                LANGUAGE / لغة
              </div>
              <Select
                defaultValue={
                  this.props.selectedLanguage
                    ? this.props.selectedLanguage
                    : "us_US"
                }
                style={{ width: 220 }}
                onChange={value => this.languageChanged(value)}
              >
                <Option value="us_US">English</Option>
                <Option value="ar_AE">العربية</Option>
              </Select>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col
              xs={{ span: 22 }}
              lg={{ span: 12 }}
              order={this.state.selectedLanguage === "us_US" ? 1 : 2}
            >
              <div
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "light",
                  fontWeight: 100,
                  fontSize: "17px",
                  lineHeight: "23px",
                  width: "100%",
                  marginTop: "40px",
                  color: "#000"
                }}
              >
                {this.state.selectedLanguage === "us_US" ? (
                  <div>
                    {" "}
                    Welcome and thank you for agreeing to take this survey.
                    <br /> <br /> We are doing research on{" "}
                    <strong>
                      urban food consumption habits and carbon intensity of
                      diets.
                    </strong>{" "}
                    This survey is divided into three sections. Section one
                    collects some general information about you. Section two is
                    for you to describe what you eat on a typical day. While you
                    are working on this section, you will get immediate feedback
                    on both calories and carbon content of your diet. The
                    underlying data is country-specific. Section three covers
                    your grocery shopping habits.
                    <br /> <br /> A summary of your personal{" "}
                    <strong> environmental foodprint</strong> will be presented
                    to you at the end and you can compare yourself to the
                    average citizen of your country as well as in your group (if
                    applicable). While the survey is open to anybody, we are
                    working with several institutions and companies who would
                    like to better understand their organization’s carbon
                    emissions from food consumption.
                    <br /> <br />
                    The entire survey should take you about{" "}
                    <strong>15 minutes</strong> and all responses will be kept{" "}
                    <strong>confidential</strong>.
                  </div>
                ) : (
                  <div style={{ textAlign: "right", direction: "rtl" }}>
                    أهلًا وسهلًا، شكرًا لموافقتك للمشاركة في تعبئة الاستبيان.
                    <br /> <br />
                    نحن نعمل على البحث في مجال العادات الغذائية المتبعة داخل
                    المدن و مدى تأثير الانظمة الغذائية في انبعاثات ثاني اكسيد
                    الكربون. هذا الاستبيان ينقسم الى ثلاثة اقسام.
                    <br /> <br />
                    القسم الأول مخصص لجمع بعض المعلومات العامة عنك. القسم الثاني
                    سيتيح لك الفرصة لوصف نظامك الغذائي خلال يومك الطبيعي. خلال
                    تعبئتك لهذا القسم، سيتفاعل معك الاستبيان بشكل مباشر و سيقدم
                    لك كمية السعرات الحرارية و نسبة انبعاثات ثاني اكسيد الكربون
                    من نظامك الغذائي. القسم الثالث سيقوم بتغطية عادات تسوقك
                    الغذائي.
                    <br /> <br />
                    في نهاية الاستبيان سيقدم لك ملخص عن البصمة الغذائية الخاصة
                    بك، و رسم بياني يتيح لك الفرصة لمقارنة تأثير نظام غذائك على
                    البيئة مقارنةً بالمواطن المتوسط في بلدك و المتوسط في نظام
                    حميتك الغذائية (إن وجد). الاستبيان مفتوح للجميع، مع العلم
                    أننا نعمل مع العديد من الجهات و الشركات التي تسعى إلى
                    التعاون البحثي و تسعى لفهم مقدار ثاني أكسيد الكربون المنبعث
                    من الاستهلاك الغذائي لديهم.
                    <br /> <br />
                    الاستبيان بأكمله سيستغرق كحد أقصى ١٥ دقيقة و جميع الردود
                    ستبقى سرية.
                  </div>
                )}
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 11 }}
              order={this.state.selectedLanguage === "us_US" ? 2 : 1}
            >
              {this.state.selectedLanguage === "us_US" ? (
                <div
                  style={{
                    backgroundImage: "url(" + introGraph + ")",
                    backgroundSize: this.props.matches.large
                      ? "contain"
                      : "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                    marginTop: this.props.matches.large ? "0px" : "30px",
                    height: this.props.matches.large ? "550px" : "209px",
                    width: "100%"
                    // marginTop: "40px",
                    // marginLeft: "40px"
                  }}
                ></div>
              ) : (
                <div
                  style={{
                    backgroundImage: "url(" + introGraph_AE + ")",
                    backgroundSize: this.props.matches.large
                      ? "contain"
                      : "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                    marginTop: this.props.matches.large ? "0px" : "30px",
                    height: this.props.matches.large ? "550px" : "209px",
                    width: "100%"
                    // marginTop: "40px",
                    // marginLeft: "40px"
                  }}
                ></div>
              )}
            </Col>
          </Row>

          <Row type="flex" justify="start">
            <Col xs={{ span: 15 }} lg={{ span: 10 }}>
              <div
                style={{
                  backgroundImage: "url(" + mitLogo + ")",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  //backgroundPosition: "50% 50%",
                  height: "70px",
                  marginTop: "50px"

                  //width:  "100px"
                }}
              ></div>
              <div
                style={{
                  height: "90px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  marginTop: "50px",
                  fontSize: "18px",
                  color: "#000"
                  //width:  "100px"
                }}
              >
                Partners:
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "100px",
                  width: "100%",
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    backgroundImage: "url(" + cces_logo + ")",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    marginTop: "10px",
                    height: "91px",
                    width: "130px"
                  }}
                ></div>
                <div
                  style={{
                    backgroundImage: "url(" + kpf_logo + ")",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    marginTop: "10px",
                    marginLeft: "25px",
                    height: "130px",
                    width: "130px"
                  }}
                ></div>
              </div>
            </Col>
            <Col xs={{ span: 22 }} lg={{ span: 10 }}>
              <Link to="/survey_page_1">
                <div
                  style={
                    this.props.matches.large
                      ? null
                      : {
                          width: "100%",
                          display: "flex",
                          justifyContent: "center"
                        }
                  }
                >
                  <div
                    // onClick={() => this.getFoodFromDB()}

                    style={{
                      background: "#004445",
                      width: "160px",
                      height: "50px",
                      textTransform: "uppercase",
                      padding: "15px",
                      marginTop: "50px",
                      textAlign: "center",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "15px",
                      lineHeight: "18px",
                      color: "#F6F6F6",
                      borderRadius: "5px"
                    }}
                  >
                    {dicionary[this.state.selectedLanguage].go_to_survey}
                  </div>
                </div>
              </Link>
            </Col>
          </Row>

          {/* <div
            onClick={() => this.sendTestSurvey()}
            style={{
              background: "#46645B",
              width: "188px",
              height: "63px",
              textTransform: "uppercase",
              padding: "20px",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "15px",
              lineHeight: "18px",
              color: "#F7F432",
              borderRadius: "5px"
            }}
          >
            Send Test SURVEY
          </div> */}
        </div>
      </>
    );
  }
}

export default SurveyHome;
