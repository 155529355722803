import React, { Component } from "react";
import DefiningUrbanFoodprint from "../components/getYourFoodPrint/DefiningUrbanFoodprint";
import MainForm from "../components/getYourFoodPrint/MainForm";
import SelectCity from "../components/getYourFoodPrint/SelectCity";
// import { Row, Col } from "antd";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { slide as Menu } from "react-burger-menu";
import "../styles/App.css";
import "../styles/header.css";
import "../styles/Hero.css";

class GetYourFoodPrint extends Component {
  constructor(props) {
    super(props);

    this.scrollTo = this.scrollTo.bind(this);
  }

  scrollTo(item) {
    console.log(item);
    window.scrollTo({
      top: document.getElementById(item).getBoundingClientRect().bottom,
      behavior: "smooth"
    });
  }

  render() {
    return (
      <>
        <DefiningUrbanFoodprint />

        <SelectCity scrollTo={this.scrollTo} />

        <MainForm />
      </>
    );
  }
}

export default GetYourFoodPrint;
