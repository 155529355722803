import React, { Component } from "react";
import { Row, Col } from "antd";
//import "./styles/App.css";
//import "../styles/new_page/ourWork_root.css";

//import UFP_Dashboard from "../img/UFP_Dashboard.svg";
//import ourWork_text from "../img/new/ourWork_full_text.svg";
import publication_1 from "../img/refactoredVersion/publications/image_block1.svg";
import publication_2 from "../img/refactoredVersion/publications/image_block2.svg";
import publication_3 from "../img/refactoredVersion/publications/image_block3.svg";
import publication_4 from "../img/refactoredVersion/publications/image_block4.svg";
import publication_5 from "../img/refactoredVersion/publications/image_block5.svg";
import publication_6 from "../img/refactoredVersion/publications/image_block6.svg";

import link_icon from "../img/refactoredVersion/publications/link_icon.svg";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
//import readMoreButton from "../img/new/read_more_button.svg";

var textStyle = { width: "100%", padding: 30 };
var sizes = { xs: { span: 24 }, lg: { span: 18, offset: 0 } };
export default class Publications extends Component {
  // constructor(props) {
  //   super(props);
  // }
  scrollTo(item) {
    console.log(item);
    window.scrollTo({
      top: document.getElementById(item).getBoundingClientRect().bottom,
      behavior: "smooth"
    });
  }
  componentDidMount() {
    console.log(this.props);
    if (this.props.location.state && this.props.location.state.scrollTo) {
      this.scrollTo(this.props.location.state.scrollTo);
    }
  }

  renderEachBlock(
    blockTitle,
    blockYear,
    BGimage,
    text,
    buttonLink,
    authors,
    articleTitle
  ) {
    const yearStyle = {};

    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "550px",
          color: "white",
          backgroundColor: "#303030",
          marginBottom: "20px"
        }}
      >
        {/* TOP BAR */}
        <div
          style={{
            padding: "10px",
            width: "100%",
            backgroundColor: "#353535",
            height: "50px"
          }}
        >
          <div
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#FFFFFF",
              height: "100%",
              maxWidth: "80%"
            }}
          >
            {blockTitle}
          </div>
          <div
            style={{
              position: "absolute",
              right: "5px",
              top: "5px",

              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "36px",
              lineHeight: "45px",
              textAlign: "center",
              color: "#26C485"
            }}
          >
            {blockYear}
          </div>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "300px",
            backgroundColor: "#fff",
            overflow: "hidden"
          }}
        >
          {/* Background Image*/}
          <img
            id="publications"
            style={{
              position: "absolute",
              width: "100%",
              top: 0,
              padding: 30,
              zIndex: 5
            }}
            //onClick={() => this.toggleLeft()}
            src={BGimage}
            alt="imageBg"
          />
          {/* Background Overlay */}
          <div
            style={{
              position: "absolute",
              backgroundColor: "#0009",
              zIndex: 7,
              width: "100%",
              height: "100%",
              padding: 30
            }}
          ></div>
          {/* Text OVER */}
          <div
            style={{
              position: "absolute",
              top: 20,
              zIndex: 10,
              color: "#fff",
              width: "100%",
              padding: 30,

              fontFamily: "Roboto",
              fontStyle: "italic",
              fontWeight: "300",
              fontSize: "16px",
              lineHeight: "20px"
            }}
          >
            {text}
          </div>
        </div>
        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "16px",
            lineHeight: "19px",
            color: "#fff",
            paddingLeft: "30px",
            paddingTop: "30px",
            paddingRight: "30px"
          }}
        >
          {authors}
        </div>
        <div
          style={{
            display: "inline-block",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "19px",
            color: "#26C485",
            paddingLeft: "30px",
            paddingRight: "30px"
          }}
        >
          {articleTitle}
        </div>
        <a target="_blank" rel="noopener noreferrer" href={buttonLink}>
          <img
            style={{
              position: "absolute",
              bottom: "20px",
              // width: "150px",
              right: "20px"
            }}
            //className="graphsDiv"
            //onClick={() => this.toggleLeft()}
            src={link_icon}
            alt="link_icon"
          />
        </a>
      </div>
    );
  }

  render() {
    return (
      <>
        <div style={{ backgroundColor: "#F6F6F6", paddingBottom: "60px" }}>
          <div className="full_about">Publications </div>

          <Row type="flex" justify="space-around">
            <Col xs={{ span: 22 }} lg={{ span: 7, offset: 0 }}>
              {this.renderEachBlock(
                "Int. J. of Design & Nature and Ecodynamics 13(4): 349-360", //block title
                "2018", //year
                publication_1, // bgimage
                "“[...] the Urban Foodprints (UF) methodology is a fundamental preliminary step in the sustainability assessment of high-yield urban agriculture, consisting of collecting and integrating data on the existing supply chain, to be used as a baseline scenario in the environmental performance analysis.”",
                "https://www.witpress.com/elibrary/dne-volumes/13/4/2346", //link
                "K Benis, R Gashgari, A Alsaati, C Reinhart",
                "Urban Foodprints (UF) – Establishing baseline scenarios for the sustainability assessment of high-yield urban agriculture"
              )}
            </Col>
            <Col xs={{ span: 22 }} lg={{ span: 7, offset: 0 }}>
              {this.renderEachBlock(
                "Global Food Security 17: 30-37", //block title
                "2018", //year
                publication_2, // bgimage
                "“[...] while the recent proliferation of commercial farms in major cities shows that these new modes of urban agricultural production are gaining momentum, establishing their viability as compared to conventional agricultural practices is a challenge when it comes to scalability, resource efficiency, and cost-effectiveness.”",
                "https://www.sciencedirect.com/science/article/abs/pii/S2211912417301207", //link
                "K Benis and P Ferrão",
                "Commercial farming within the urban built environment – Taking stock of an evolving field in northern countries"
              )}
            </Col>
            <Col xs={{ span: 22 }} lg={{ span: 7, offset: 0 }}>
              {this.renderEachBlock(
                "Cities 78: 166-179", //block title
                "2018", //year
                publication_3, // bgimage
                "“[...] rooftop greenhouse farming can provide significant benefits over rooftop green roof and solar PV systems when assessed from a holistic perspective that accounts for impacts on both the operator and the local community.”",
                "https://www.sciencedirect.com/science/article/abs/pii/S0264275117306911", //link
                "K Benis, I Turan, C Reinhart, P Ferrão",
                "Putting rooftops to use – A Cost-Benefit Analysis of food production vs. energy generation under Mediterranean climates"
              )}
            </Col>
            <Col xs={{ span: 22 }} lg={{ span: 7, offset: 0 }}>
              {this.renderEachBlock(
                "Proceedings of Building Simulation 2017 San Francisco, USA", //block title
                "2017", //year
                publication_4, // bgimage
                "“[...] while rooftop greenhouse farms can significantly reduce GHG emissions under all the tested climates – shipping container farms may only have a positive overall environmental impact in megacities located in colder climates, that seasonally rely on long distance food imports.”",
                "http://www.ibpsa.org/proceedings/BS2017/BS2017_479.pdf", //link
                "K Benis, C Reinhart, P Ferrão ",
                "Building-Integrated Agriculture (BIA) in urban contexts: Testing a simulation-based decision support workflow"
              )}
            </Col>
            <Col xs={{ span: 22 }} lg={{ span: 7, offset: 0 }}>
              {this.renderEachBlock(
                "Journal of Cleaner Production 147: 589-602", //block title
                "2017", //year
                publication_5, // bgimage
                "“[...] in Lisbon, Portugal [...] rooftop greenhouses can cut greenhouse gas emissions in half in comparison with the existing supply chain for tomato.” \b “[...] the workflow provides the user with actionable information for early-stage holistic assessment of BIA projects.”",
                "https://www.sciencedirect.com/science/article/pii/S0959652617301452", //link
                "K Benis, C Reinhart, P Ferrão",
                "Development of a simulation-based decision support workflow for the implementation of Building-Integrated Agriculture (BIA) in urban contexts"
              )}
            </Col>
            <Col xs={{ span: 22 }} lg={{ span: 7, offset: 0 }}>
              {this.renderEachBlock(
                "Journal of Cleaner Production 140: 784-795", //block title
                "2017", //year
                publication_6, // bgimage
                "“[...] the highest potential for environmental impacts mitigation is related to dietary changes. However, strategies for enhancing the efficiency of the food supply chain are relevant, as reducing losses and wastage, shortening transportation distances and taking into account technology improvements can further increase the mitigation potential.”",
                "https://www.sciencedirect.com/science/article/pii/S0959652616306552", //link
                "K Benis and P Ferrão",
                "Potential mitigation of the environmental impacts of food systems through Urban and Periurban Agriculture (UPA) – A life cycle approach"
              )}
            </Col>
          </Row>
        </div>
        <Footer />
      </>
    );
  }
}
