import React, { Component } from "react";
import { Row, Col } from "antd";
//import "./styles/App.css";
//import "../styles/new_page/ourWork_root.css";

//import UFP_Dashboard from "../img/UFP_Dashboard.svg";

import Teaching from "../img/new/ourWork_teaching.svg";
//import tryharvest from "../img/new/tryHarvest.svg";

import Footer from "../components/footer";
//import readMoreButton from "../img/new/read_more_button.svg";

export default class TeachingPage extends Component {
  // constructor(props) {
  //   super(props);
  // }
  scrollTo(item) {
    console.log(item);
    window.scrollTo({
      top: document.getElementById(item).getBoundingClientRect().bottom,
      behavior: "smooth"
    });
  }
  componentDidMount() {
    console.log(this.props);
    if (this.props.location.state && this.props.location.state.scrollTo) {
      this.scrollTo(this.props.location.state.scrollTo);
    }
  }

  renderEachBlock(icon, title, text, buttonLink) {}

  render() {
    return (
      <div /*className="ourWork_root"*/>
        <Row type="flex" justify="center">
          <Col xs={{ span: 24 }} lg={{ span: 24, offset: 0 }}>
            <img
              style={{ width: "100%" }}
              //onClick={() => this.toggleLeft()}
              src={Teaching}
              alt="Teaching"
            />
          </Col>{" "}
          <Col xs={{ span: 24 }} lg={{ span: 24, offset: 0 }}>
            <div
              style={{
                position: "relative",
                textAlign: "center",
                color: "white"
              }}
            >
              <div className="ourWork_bg_image" alt="ourWork_seminar" />
              <Row type="flex" justify="start">
                <Col xs={{ span: 24 }} lg={{ span: 8, offset: 1 }}>
                  <div className="container">
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "13px",
                        lineHeight: "21px",
                        letterSpacing: "0.1em",
                        textTransform: "uppercase",
                        color: "#FFFFFF"
                      }}
                    >
                      RESEARCH SEMINAR
                    </div>
                    <div
                      style={{
                        paddingBottom: "3vh",
                        fontFamily: "Quicksand",
                        fontStyle: "normal",
                        fontWeight: "300",
                        fontSize: "38px",
                        lineHeight: "60px",
                        color: "#FFFFFF"
                      }}
                    >
                      Food <strong>for Thought</strong>
                    </div>

                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "15px",
                        lineHeight: "191%",
                        letterSpacing: "0.03em",
                        color: "#FFFFFF"
                      }}
                    >
                      This seminar explores the environmental impacts of our
                      food choices and their potential mitigation through
                      high-yield urban agriculture. Weekly sessions initially
                      guide students through the process of quantifying their
                      individual, food-related carbon emissions. During the
                      second half of the class, students work in groups to
                      develop a low carbon food concept for a neighborhood of
                      their choice, relying on MIT’s building-integrated
                      agriculture simulation tool HARVEST. Our analysis includes
                      a series of impact studies from replacing current modes of
                      agriculture with new technologies such as meat
                      replacements and hydroponic farms and cover economic
                      viability, local jobs created and system-level carbon
                      emissions.
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            {/* <div
            //style={{ height: "70vh", padding: "10vh", alignItems: "center" }}
            >
              <img
                //onClick={() => this.toggleLeft()}
                src={ourWork_harvest}
                alt="ourWork_harvest"
              />
            </div> */}
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24, offset: 0 }}>
            <Footer />
          </Col>
        </Row>
      </div>
    );
  }
}
