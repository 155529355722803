var address;
if (process.env.NODE_ENV === "production") {
  address = "https://urbanfoodprints.com/api";
} else {
  address = "http://localhost:3000";
}

let foodGroupsNameTranslation = {
  Sugar_and_Fat: "sugar_fat",
  Eggs_and_Dairy: "dairy_eggs",
  Grain: "grain",
  Meat: "meat",
  Other: "other",
  Produce: "produce"
};
//eslint-disable-next-line
//import { handleResponse, authenticate } from "./common";

export async function getFood() {
  // const userInfo = await authenticate();
  // if (!userInfo) {
  //   return null;
  // }

  const response = await fetch(address + "/unauth/food", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //  jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  let body = await response.json();
  return body;
  //return await handleResponse(response);
}
export async function getCountryFoodgroups(country) {
  //UNAUTH
  // const userInfo = await authenticate();
  // if (!userInfo) {
  //   return null;
  // }

  const response = await fetch(address + "/unauth/country_data", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //  jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  let allCountriesData = await response.json();

  /*
{
        sugar_fat: { co2: null, calories: null },
        grain: { co2: null, calories: null },
        dairy_eggs: { co2: null, calories: null },
        other: { co2: null, calories: null },
        produce: { co2: null, calories: null },
        meat: { co2: null, calories: null }
*/

  allCountriesData = allCountriesData.filter(item => item.country === country);

  let foodGroup = {};
  allCountriesData.forEach(item => {
    if (item.group)
      foodGroup[foodGroupsNameTranslation[item.group]] = {
        co2: item.co2,
        calories: item.cal
      };
  });

  return foodGroup;
  //return await handleResponse(response);
}

export async function getSameGroupFoodgroups(group) {
  //UNAUTH
  // const userInfo = await authenticate();
  // if (!userInfo) {
  //   return null;
  // }
  const response = await fetch(
    address + "/unauth/survey_stats/" + encodeURIComponent(group),
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //  jwt: userInfo.signInUserSession.accessToken.jwtToken
      }
    }
  );
  let sameGroupFoodgroups = await response.json();

  return sameGroupFoodgroups;
}

export async function sendSurvey(survey) {
  //Post one survey

  //MIDDLEWARE //TODO - pass to another function
  if (survey.answers.length === 0) {
    return { result: "failure", error: true };
  }
  //We need some sort of middleware to add dummy null plates in answers with "no plates", to respect required model.
  //Made to avoid errors in the future survey data

  survey.answers.forEach(answer => {
    if (answer.type !== "plates") {
      answer.plates = [
        {
          meal: "no meal",
          foodQuantity: 9999,
          portions: [null],
          calories: 9999,
          co2: 9999
        }
      ];
    }
  });

  const response = await fetch(address + "/unauth/survey", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //  jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(survey)
  });

  let body = await response.json();
  return body;
  //return await handleResponse(response);
}
