import React, { Component } from "react";
import { Row, Col } from "antd";
//import "./styles/App.css";
import "./styles/Assessment.css";
import { Link } from "react-router-dom";
import UFP_Dashboard from "./img/UFP_Dashboard.svg";
import underHero from "./img/new/home_under.svg";
import readMoreButton from "./img/new/read_more_button.svg";
export default class Assessment extends Component {
  render() {
    return (
      <div className="assmt-background" id="assmt-start_toScroll">
        <Row type="flex" justify="center">
          <Col xs={{ span: 20 }} lg={{ span: 20, offset: 0 }}>
            <img
              id="assmt-title"
              className="graphsDiv"
              //onClick={() => this.toggleLeft()}
              src={underHero}
              alt="underHero"
            />
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 18, offset: 0 }}>
            <div className="assmt-text">
              Over two-thirds of the global population is expected to live in
              urban areas by 2050, and meeting food demand of our growing cities
              will require raising production by 70% by 2050 with dwindling
              resources and finite arable land. Controlled-environment urban
              farming allows growers to protect crops against weather risks,
              while using less resources and producing more and closer to
              consumers. However, there is a significant knowledge gap for
              stakeholders entering the market regarding crop yield as well as
              required water and energy use. Data can close this gap by helping
              urban farmers cut costs, increase revenue, reduce carbon emissions
              and make the supply chain more sustainable and efficient. <br />{" "}
              <br />
              Defining Urban Foodprints consists of getting snapshots of the
              existing food system for a given urban area, using metrics related
              to food demand, resource use intensity of production, and food
              miles, to estimate the overall environmental impacts caused by the
              supply of a given produce to the city. Comparing this Foodprint to
              the environmental impacts of local production allows to determine
              whether it is sustainable to grow food on-site.
              <br />
              <br />
              We believe that the future of food is urban and our mission is to
              use the Urban Foodprint concept to raise awareness and promote the
              transition toward sustainable urban food systems.
              <br />
            </div>
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 20 }}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Link
                to={{
                  pathname: "/getyourfoodprint"
                  // state: {
                  //   scrollTo: "publications"
                  // }
                }}
              >
                <img
                  style={{ marginTop: "100px", marginBottom: "100px" }}
                  //onClick={() => thidss.props.scrollTo("assmt-title")}
                  src={readMoreButton}
                  alt="readMoreButton"
                />
              </Link>{" "}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
