import React, { Component } from "react";
import { Row, Col } from "antd";
//import "./styles/App.css";
import "./../../styles/SelectCity.css";
import "./../../styles/header.css";
import { Link } from "react-router-dom";
import ReadPaper from "./../../img/refactoredVersion/getYourFoodprint/readmore_green_button.svg";
import systemBoundary from "./../../img/refactoredVersion/getYourFoodprint/systemBoundary.svg";
import middle_pic from "./../../img/refactoredVersion/getYourFoodprint/middle_pic.png";
import traktor from "./../../img/refactoredVersion/getYourFoodprint/traktor.png";
import download_button from "./../../img/new/home/download.svg";
import watchTutorial from "./../../img/new/home/watchTutorial.svg";

export default class DefiningUrbanFoodprint extends Component {
  renderYoutube(youtubeID, title, text) {
    return (
      <div style={{ padding: 20, marginBottom: 50 }}>
        <div
          className="video"
          style={{
            position: "relative",
            paddingBottom: "56.25%" /* 16:9 */,
            paddingTop: 25,
            height: 0
          }}
        >
          <iframe
            title={youtubeID}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
            src={"https://www.youtube.com/embed/" + youtubeID}
            allowFullScreen
            frameBorder="0"
          />
        </div>
        <div
          style={{
            fontFamily: "Roboto",
            fontSize: "30px",
            paddingTop: 30,
            color: "white"
          }}
        >
          {title}
          <br />
        </div>

        <div style={{ fontFamily: "Roboto", fontSize: "17px", color: "white" }}>
          {text}
        </div>
      </div>
    );
  }
  renderBlock(importedImg, title, text) {
    return (
      <div
        style={{
          textAlign: "center",
          marginBottom: 20,
          padding: 38,
          width: "300px",
          height: "500px",
          background: "#F6F6F6"
        }}
      >
        <div
          style={{
            height: 100,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
        >
          <img
            style={{ margin: 10, maxHeight: 100, maxWidth: 100 }}
            //onClick={() => this.toggleLeft()}
            src={importedImg}
            alt="icon"
          />
        </div>
        <div
          capital
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "15px",
            lineHeight: "29px",
            textAlign: "center",
            letterSpacing: "0.03em",
            textTransform: "uppercase",
            color: "#353535"
          }}
        >
          {title}
        </div>

        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "12px",
            lineHeight: "177.04%",
            textAlign: "left"
          }}
        >
          {text}
        </div>
      </div>
    );
  }

  render() {
    const greenBoldTextStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "191.02%",
      letterSpacing: "0.03em",
      color: "#26C485",
      cursor: "pointer"
    };

    return (
      <div
        style={{
          height: "auto",
          width: "100%",

          padding: "20px",
          backgroundColor: "#353535",

          paddingBottom: "40px"
          // paddingBottom: 50
        }}
      >
        <div
          style={{
            paddingTop: "60px",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "40px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "15px",
            lineHeight: "29px",
            letterSpacing: "0.03em",
            color: "#FFFFFF"
          }}
        >
          Defining Urban Foodprints consists of getting snapshots of the
          existing food system for a given urban area, using metrics related to
          food demand, resource use intensity of production, and food miles, to
          estimate the overall environmental impacts caused by the supply of a
          given produce to the city. Once the system boundary is defining, data
          on yields and resource use is collected and emissions are calculated.
        </div>
        <Row type="flex" justify="space-around">
          <Col xs={{ span: 20 }} lg={{ span: 7 }}>
            {this.renderBlock(
              systemBoundary,
              "SYSTEM BOUNDARY",

              `Life cycle assessments of food products are usually conducted
                from cradle to farm gate, focusing on the most
                environmentally-harmful phase of the products’ lifespan,
                cultivation. To enable comparisons of the existing supply chain
                with scenarios of local food production, Urban Foodprints
                encompass a larger set of activities, from cradle to
                distribution, in such a way as to include travel distances and
                transportation modes from farm gate to the assessed urban area.`
            )}
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 7 }}>
            {this.renderBlock(
              middle_pic,
              "YIELDS",
              `Gauging to what extent urban farms can be more efficient than their
              conventional counterparts requires comparing respective yields. For that purpose, based on the collected data on
              origin of produce, it is necessary to further investigate the dominant cultivation
              conditions for each location including local climate conditions, whether the crop is produced
              in open fields or in protected environments as well as the length of the growing season. `
            )}
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 7 }}>
            {this.renderBlock(
              traktor,
              "RESOURCE USE",

              `Building an Urban Foodprint also requires
              data on the amount of water use for irrigation as well as average energy use for climate control
              (in case of conditioned greenhouse farming) and machinery. Greenhouse gas (GHG) emissions related to irrigation, operational energy and transportation
              of the existing supply chain of the assessed crop to the city is then calculated (expressed
              in kgCO2eq/kg), using the relevant emission factors.`
            )}
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col>
            <img
              onClick={() =>
                window.open(
                  "https://www.witpress.com/elibrary/dne-volumes/13/4/2346",
                  "_blank"
                )
              }
              alt={"ReadPaper"}
              style={{
                marginBottom: "50px",
                marginTop: "50px",
                cursor: "pointer"
              }}
              src={ReadPaper}
            />
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col>
            {" "}
            <div
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "15px",
                lineHeight: "29px",
                letterSpacing: "0.03em",
                color: "#FFFFFF"
              }}
            >
              To get anUrban Foodprint JSON file of your city for HARVEST, you
              can either use the{" "}
              <span
                onClick={() =>
                  window.scrollTo({
                    top: 1900,
                    behavior: "smooth"
                  })
                }
                style={greenBoldTextStyle}
              >
                UFP Map
              </span>{" "}
              to browse and download existing UFP files, or fill in the form of
              the{" "}
              <span
                onClick={() =>
                  window.scrollTo({
                    top: 2500,
                    behavior: "smooth"
                  })
                }
                style={greenBoldTextStyle}
              >
                FoodPrint generator{" "}
              </span>{" "}
              to create and export your own JSON file.
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={{ span: 20 }} lg={{ span: 9, offset: 0 }}>
            <div style={{ marginTop: 50, textAlign: "center", width: "100%" }}>
              {this.renderYoutube(
                "GtFQNWsL3dY",
                "Get your FOODprint",
                <div>
                  Duration 4:46 | Tutorial 03 <br />
                  <br />
                  This tutorial shows you how to get a FOODprint JSON file for
                  your city, using the UFP or the FOODprint Generator. You will
                  need this file as a simulation input in HARVEST. Learn more{" "}
                  <span
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                      })
                    }
                    style={greenBoldTextStyle}
                  >
                    <Link
                      to={{
                        pathname: "/harvest"
                      }}
                    >
                      here
                    </Link>
                  </span>
                  .
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Row type="flex" justify="center">
          <Col xs={{ span: 20 }} lg={{ span: 8, offset: 0 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                textAlign: "center"
              }}
            >
              <div
                onClick={() =>
                  window.scrollTo({
                    top: 1900,
                    behavior: "smooth"
                  })
                }
                style={{
                  width: "160px",
                  height: "40px",
                  borderRadius: "3px",
                  padding: "10px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#FFF",
                  textAlign: "center"
                  //float: "left"
                }}
              >
                UFP Map
              </div>{" "}
              <div
                onClick={() =>
                  window.scrollTo({
                    top: 2500,
                    behavior: "smooth"
                  })
                }
                style={{
                  width: "180px",
                  height: "40px",
                  borderRadius: "3px",
                  padding: "10px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "13px",
                  backgroundColor: "#353535",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "white",
                  color: "white",
                  textAlign: "center"
                  //float: "right"
                }}
              >
                FOODPrint Generator
              </div>
            </div>
            {/* <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://gmail.us20.list-manage.com/subscribe?u=2a6b2b3cbcd0647d2a73f3d8b&id=2d8999336c"
              }
            >
              <img
                style={{
                  // width: "50%"
                  marginBottom: "50px"
                }}
                //onClick={() => this.toggleLeft()}
                src={download_button}
                alt="download_button"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://www.youtube.com/watch?v=6R6js9i5Cu0"}
            >
              <img
                style={{
                  // width: "50%",
                  marginLeft: "0px",
                  marginBottom: "50px"
                }}
                //onClick={() => this.toggleLeft()}
                src={watchTutorial}
                alt="watchTutorial"
              />
            </a> */}
          </Col>
        </Row>
      </div>
    );
  }
}
