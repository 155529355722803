import React, { Component } from "react";

import { Link } from "react-router-dom";
import { dicionary } from "../../dicionary";
import {
  CountryDropdown,
  RegionDropdown,
  // CountryRegionData
} from "react-country-region-selector";

import { Radio, Select } from "antd";

const { Option } = Select;

const radioOptionsStyle = {
  display: "block",
  height: "30px",
  color: "#FFFFFF",
  fontFamily: "Roboto",
  fontStyle: "light",
  fontWeight: "300",
  fontSize: "15px",
  lineHeight: "29px",
};

const radioTitleStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "19px",
  lineHeight: "25px",
  color: "#FFFFFF",
  paddingTop: "85px",
};
const groupsOptions = [
  { label: "", value: "1" },
  { label: "", value: "2" },
  { label: "", value: "3" },
  { label: "", value: "4" },
  { label: "", value: "5" },
];

const groupAnswers = {
  width: "100%",
  background: "#FFFFFF4f",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "right",
  //display: "inline-block"
};

const answersMarginLeft = {
  marginLeft: "70px",
  padding: "20px",
};

class Survey_Page_1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLanguage: "us_US",
    };
    // this.addAnswerToSurvey = this.props.addAnswerToSurvey.bind(this);
  }

  componentDidMount() {
    //if question 5_1_groupMember is not touched, an answer must exist anyway

    if (!this.getAnswerFromProp("5_1_groupMember")) {
      this.props.addAnswerToSurvey({
        description: "Are you a member of any of these groups?",
        survey_question: "5_1_groupMember",
        answer: "None of them",
        type: "single_answer",
        groupAnswer: false,
        othersValue: false,
        plates: false,
      });
    }

    if (this.props.selectedLanguage) {
      this.props.setLanguage(this.props.selectedLanguage);
    }
  }
  // languageChanged(value) {
  //   console.log(`selected ${value}`);
  //   this.setState({ selectedLanguage: value });

  //   this.props.setLanguagevalue()
  // }

  // onChangeRadio = answer_data => {
  //   console.log("survey_question", answer_data.survey_question);

  //   console.log("radio answer_data", answer_data);
  //   console.log("answer: ", answer_data.answer);

  //   // this.setState({
  //   //   value
  //   // });
  // };

  // handle_Question_5_change(value) {
  //   console.log(`selected ${value}`);
  // }

  // onChange1 = e => {
  //   console.log("radio1 checked", e.target.value);
  //   this.setState({
  //     value1: e.target.value
  //   });
  // };

  // onChange2 = e => {
  //   console.log("radio2 checked", e.target.value);
  //   this.setState({
  //     value2: e.target.value
  //   });
  // };

  // onChange3 = e => {
  //   console.log("radio3 checked", e.target.value);
  //   this.setState({
  //     value3: e.target.value
  //   });
  // };

  validateAnswers() {
    if (
      this.getAnswerFromProp("1_1_gender") &&
      this.getAnswerFromProp("2_1_age") &&
      this.getAnswerFromProp("3A_1_country") &&
      this.getAnswerFromProp("3B_1_region") &&
      this.getAnswerFromProp("4_1_exercise") &&
      this.getAnswerFromProp("4_1_education") &&
      this.getAnswerFromProp("5_1_groupMember")
    ) {
      return true;
    } else {
      return false;
    }
  }

  getAnswerFromProp(question) {
    let answer;
    let result;
    if (
      this.props.answers &&
      this.props.answers.find((answer) => answer.survey_question === question)
    ) {
      result = this.props.answers.find(
        (answer) => answer.survey_question === question
      ).answer;
    } else {
      result = null;
    }
    //console.log("result", result);

    return result;
  }
  render() {
    console.log("this.props.answer", this.props.answers);
    return (
      <>
        <div
          style={{
            //backgroundImage: "url(" + bgimage + ")",
            background: this.props.matches.large ? "#004445" : "#004445",
            padding: this.props.matches.large ? "100px" : "30px",
            paddingTop: this.props.matches.large ? "60px" : "80px",
            height: "auto",
            width: "100%",
            textAlign:
              this.props.selectedLanguage === "ar_AE" ? "right" : "left",
            direction: this.props.selectedLanguage === "ar_AE" ? "rtl" : null,
          }}
        >
          <div
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "30px",
              lineHeight: "45px",
              color: "#fff",
            }}
          >
            {
              dicionary[this.props.selectedLanguage]
                .whats_the_carbon_of_your_food
            }{" "}
          </div>
          <div
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "20px",
              lineHeight: "30px",
              paddingTop: "15px",
              color: "#FFFFFF",
            }}
          >
            {dicionary[this.props.selectedLanguage].page1from3}
          </div>
          <div
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "30px",
              lineHeight: "50px",
              padding: "15px",
              color: "#FFFFFF",
            }}
          >
            {dicionary[this.props.selectedLanguage].seccion1_about_you}
          </div>
          {/* Section 1. About you */}
          <div>
            <div style={radioTitleStyle}>
              {dicionary[this.props.selectedLanguage].question1_title}
            </div>
            {/* 1. What is your gender? */}
            <Radio.Group
              style={{ ...answersMarginLeft }}
              onChange={(e) =>
                this.props.addAnswerToSurvey({
                  description: "What is your gender?",
                  survey_question: "1_1_gender",
                  answer: e.target.value,
                  type: "single_answer",
                  groupAnswer: false,
                  othersValue: false,
                  plates: false,
                })
              }
              value={this.getAnswerFromProp("1_1_gender")}
            >
              <Radio style={radioOptionsStyle} value={"Male"}>
                {dicionary[this.props.selectedLanguage].question1_option1}
              </Radio>
              <Radio style={radioOptionsStyle} value={"Female"}>
                {dicionary[this.props.selectedLanguage].question1_option2}
              </Radio>
            </Radio.Group>
          </div>
          <div>
            <div style={radioTitleStyle}>
              {dicionary[this.props.selectedLanguage].question2_title}
            </div>
            {/* 1. What is your gender? */}
            <Radio.Group
              style={answersMarginLeft}
              onChange={(e) =>
                this.props.addAnswerToSurvey({
                  description: "What is your age?",
                  survey_question: "2_1_age",
                  answer: e.target.value,
                  type: "single_answer",
                  groupAnswer: false,
                  othersValue: false,
                  plates: false,
                })
              }
              value={this.getAnswerFromProp("2_1_age")}
            >
              <Radio style={radioOptionsStyle} value={"Under 18"}>
                {dicionary[this.props.selectedLanguage].question2_under18}
              </Radio>

              <Radio style={radioOptionsStyle} value={"19-24"}>
                {dicionary[this.props.selectedLanguage].question2_option1}
              </Radio>
              <Radio style={radioOptionsStyle} value={"25-34"}>
                {dicionary[this.props.selectedLanguage].question2_option2}
              </Radio>
              <Radio style={radioOptionsStyle} value={"35-49"}>
                {dicionary[this.props.selectedLanguage].question2_option3}
              </Radio>
              <Radio style={radioOptionsStyle} value={"50-64"}>
                {dicionary[this.props.selectedLanguage].question2_option4}
              </Radio>
              <Radio style={radioOptionsStyle} value={"65 +"}>
                {dicionary[this.props.selectedLanguage].question2_option5}
              </Radio>
            </Radio.Group>
          </div>
          <div style={radioTitleStyle}>
            {dicionary[this.props.selectedLanguage].question3_title}
            {/* <div
              style={{
                display: "inline",
                fontWeight: "normal",
                fontSize: "medium"
              }}
            >
             
            </div> */}
          </div>
          <div style={{ padding: "20px" }}>
            <CountryDropdown
              style={{
                marginLeft: this.props.matches.large ? "70px" : "10px",
                backgroundColor: "#fff",
                padding: "10px",
                width: "300px",
              }}
              defaultOptionLabel={
                dicionary[this.props.selectedLanguage].select_country
              }
              //priorityOptions={["SA"]}
              value={this.getAnswerFromProp("3A_1_country")}
              //onChange={value => this.selectCountry(value, "test")}
              onChange={(value) => {
                // this.setState({ country: value });
                this.props.addAnswerToSurvey({
                  description: "Where do you live?",
                  survey_question: "3A_1_country",
                  answer: value,
                  type: "single_answer",
                  groupAnswer: false,
                  othersValue: false,
                  plates: false,
                });
              }}
            />
            <RegionDropdown
              style={{
                padding: "10px",
                width: "300px",
                margin: "10px",
                backgroundColor: "#fff",
              }}
              defaultOptionLabel={dicionary[this.props.selectedLanguage].region}
              country={this.getAnswerFromProp("3A_1_country")}
              value={this.getAnswerFromProp("3B_1_region")}
              onChange={(value) => {
                this.props.addAnswerToSurvey({
                  description: "Where do you live?",
                  survey_question: "3B_1_region",
                  answer: value,
                  type: "single_answer",
                  groupAnswer: false,
                  othersValue: false,
                  plates: false,
                });
                // this.setState({ region: value });
              }}
            />
          </div>
          <div>
            <div style={radioTitleStyle}>
              {dicionary[this.props.selectedLanguage].question_exercice_title}
            </div>
            {/* 1. What is your gender? */}
            <Radio.Group
              style={answersMarginLeft}
              onChange={(e) =>
                this.props.addAnswerToSurvey({
                  description: "How often do you exercise?",
                  survey_question: "4_1_exercise",
                  answer: e.target.value,
                  type: "single_answer",
                  groupAnswer: false,
                  othersValue: false,
                  plates: false,
                })
              }
              value={this.getAnswerFromProp("4_1_exercise")}
            >
              <Radio style={radioOptionsStyle} value={"Once a week or less"}>
                {
                  dicionary[this.props.selectedLanguage]
                    .question_exercice_option1
                }
              </Radio>
              <Radio style={radioOptionsStyle} value={"1-3 times a week"}>
                {
                  dicionary[this.props.selectedLanguage]
                    .question_exercice_option2
                }
              </Radio>
              <Radio
                style={radioOptionsStyle}
                value={"At least 5 times a week"}
              >
                {
                  dicionary[this.props.selectedLanguage]
                    .question_exercice_option3
                }
              </Radio>
            </Radio.Group>
          </div>
          <div>
            <div style={radioTitleStyle}>
              {dicionary[this.props.selectedLanguage].question4_title}
            </div>
            {/* 1. What is your gender? */}
            <Radio.Group
              style={answersMarginLeft}
              onChange={(e) =>
                this.props.addAnswerToSurvey({
                  description:
                    "What is the highest level of education you have completed (or are currently completing)?",
                  survey_question: "4_1_education",
                  answer: e.target.value,
                  type: "single_answer",
                  groupAnswer: false,
                  othersValue: false,
                  plates: false,
                })
              }
              value={this.getAnswerFromProp("4_1_education")}
            >
              <Radio style={radioOptionsStyle} value={"No qualification"}>
                {dicionary[this.props.selectedLanguage].question4_option1}
              </Radio>
              <Radio style={radioOptionsStyle} value={"High school"}>
                {dicionary[this.props.selectedLanguage].question4_option2}
              </Radio>
              <Radio style={radioOptionsStyle} value={"Bachelors degree"}>
                {dicionary[this.props.selectedLanguage].question4_option3}
              </Radio>
              <Radio style={radioOptionsStyle} value={"Postgraduate degree"}>
                {dicionary[this.props.selectedLanguage].question4_option4}
              </Radio>
            </Radio.Group>
          </div>
          <div style={radioTitleStyle}>
            {dicionary[this.props.selectedLanguage].question5_title}
          </div>
          <div style={{ color: "#fff" }}>
            <Select
              style={{ ...answersMarginLeft, width: "200px" }}
              defaultValue="None of them"
              //style={{ width: 120 }}
              value={
                this.getAnswerFromProp("5_1_groupMember")
                  ? this.getAnswerFromProp("5_1_groupMember")
                  : "None of them"
              }
              onChange={(value) =>
                this.props.addAnswerToSurvey({
                  description: "Are you a member of any of these groups?",
                  survey_question: "5_1_groupMember",
                  answer: value,
                  type: "single_answer",
                  groupAnswer: false,
                  othersValue: false,
                  plates: false,
                })
              }

              // onChange={this.handle_Question_5_change}
            >
          
              <Option value="Urban Agriculture 2020">
                Urban Agriculture 2020
              </Option>
              <Option value="MIT">
                {dicionary[this.props.selectedLanguage].question5_option1}
              </Option>
              <Option value="KACST">
                {dicionary[this.props.selectedLanguage].question5_option2}
              </Option>
              {/* <Option value="SOLEMMA SYMPOSIUM">
                {dicionary[this.props.selectedLanguage].question5_option3}
              </Option> */}
              <Option value="HOK">HOK</Option>
              <Option value="KPF">KPF</Option>
              <Option value="Elementa/Integral">Elementa/Integral</Option>
              <Option value="IST Lisboa">IST Lisboa</Option>
              <Option value="ARUP">ARUP</Option>
              <Option value="Testing Team">Testing Team</Option>
              <Option value="None of them">
                {dicionary[this.props.selectedLanguage].question5_option4}
              </Option>
            </Select>
          </div>

          <div
            style={{
              display: "flex",
              paddingTop: "100px",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Link to="/survey">
              <div
                style={{
                  textAlign: "center",
                  width: "171px",
                  height: "63px",
                  background: "#004445",
                  border: "rgb(255, 255, 255)",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderRadius: "4px",
                  color: "#fff",
                  textTransform: "uppercase",
                  padding: "20px",
                  margin: "5px",
                }}
              >
                {" "}
                {dicionary[this.props.selectedLanguage].previous}
              </div>
            </Link>
            <Link
              to="/Survey_Page_2_plates"
              onClick={(e) =>
                this.validateAnswers() ? null : e.preventDefault()
              }
            >
              <div
                style={{
                  textAlign: "center",
                  width: "171px",
                  height: "63px",
                  background: this.validateAnswers() ? "#fff" : "#777",
                  border: "rgb(255, 255, 255)",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderRadius: "4px",
                  color: "#004445",
                  textTransform: "uppercase",
                  padding: "20px",
                  margin: "5px",
                }}
              >
                {" "}
                {dicionary[this.props.selectedLanguage].next}
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default Survey_Page_1;
