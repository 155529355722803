import React, { Component } from "react";
import { Row, Col } from "antd";
//import "./styles/App.css";
import "../styles/AboutResearch.css";

import signupText from "../img/new/signup_text.svg";
import logo from "../img/new/footer_logo.svg";

export default class Footer extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "auto" }}>
        <Row type="flex" justify="space-between">
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 5, offset: 2 }}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://gmail.us20.list-manage.com/subscribe?u=2a6b2b3cbcd0647d2a73f3d8b&id=2d8999336c"
                }
              >
                <img
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px"
                    // marginBottom: "20px"
                  }}
                  //style={{ width: "100%", marginTop: "50px", marginLeft: "100px" }}
                  //onClick={() => this.toggleLeft()}
                  src={logo}
                  alt="logo"
                />
              </a>
            </div>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 7, offset: 5 }}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://gmail.us20.list-manage.com/subscribe?u=2a6b2b3cbcd0647d2a73f3d8b&id=2d8999336c"
                }
              >
                <img
                  style={{
                    paddingTop: "45px",
                    paddingBottom: "20px"
                    // marginBottom: "20px"
                    //height: "100px"
                  }}
                  //onClick={() => this.toggleLeft()}
                  src={signupText}
                  alt="signupText"
                />
              </a>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
