import { message, Radio } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { sendSurvey } from "../../network/foodAndSurvey";
import Img_11 from "./images/question_pictures/11.svg";
import Img_fruits_on_market from "./images/question_pictures/pag3_fruits.svg";
import { dicionary } from "../../dicionary";
import "./survey_css.css";

//const { Option } = Select;

const groupAnswers = {
  display: "flex",
  width: "100%",
  background: "#FFFFFF4f",
  height: "50px",
  marginTop: "5px",
  padding: "5px",
  alignItems: "center",
  justifyContent: "space-between"
  //display: "inline-block"
};
const radioOptionsStyle = {
  display: "block",
  height: "30px",
  color: "#fff",
  fontFamily: "Roboto",
  fontStyle: "light",
  fontWeight: "300",
  fontSize: "15px",
  lineHeight: "29px"
};

const radioTitleStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "19px",
  lineHeight: "25px",
  color: "#fff",
  paddingTop: "50px"
};
const radioSubTitleStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "25px",
  color: "#fff",
  paddingTop: "50px"
};

const groupsOptions_1To5 = [
  { label: "", value: "1" },
  { label: "", value: "2" },
  { label: "", value: "3" },
  { label: "", value: "4" },
  { label: "", value: "5" }
];
const answersMarginLeft = {
  marginLeft: "70px",
  padding: "20px"
};
class Survey_Page_2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: [],
      selectedLanguage: "us_US",
      filledSurvey: null
    };

    // this.addAnswerToSurvey = this.addAnswerToSurvey.bind(this);
    this.renderGroupAnswer = this.renderGroupAnswer.bind(this);
  }

  // componentDidMount() {
  //   if (this.props.answers) {
  //     this.setState({ answers: this.props.answers });
  //   }
  // }

  getAnswerFromProp(question) {
    let answer;
    let result;
    if (
      this.props.answers &&
      this.props.answers.find(answer => answer.survey_question === question)
    ) {
      result = this.props.answers.find(
        answer => answer.survey_question === question
      ).answer;
    } else {
      result = null;
    }
    console.log("result", result);

    return result;
  }
  languageChanged(value) {
    console.log(`selected ${value}`);
    this.setState({ selectedLanguage: value });
  }

  checkIfAllFormIsReadyToSubmit() {
    return true; // dummy to - all is good to Go //TODO!
  }

  /*
{
            title: "9. Which one of these products would you buy?",
            survey_question: "9A_2_importedOrNational",
            answers: [
              {
                value: "Imported",
                image: _9_A
              },
              {
                value: "National",
                image: _9_B
              }
            ]
          }
  */

  validateAnswers() {
    if (
      this.getAnswerFromProp("7_2_where_to_buy") &&
      this.getAnswerFromProp("12.1_1_importedOrNational") &&
      this.getAnswerFromProp("12.1_2_importedOrNational") &&
      this.getAnswerFromProp("12.2_1_nonOrganicOrOrganic") &&
      this.getAnswerFromProp("12.2_2_nonOrganicOrOrganic") &&
      this.getAnswerFromProp("13.1_1_RegularOrLowCarbon") &&
      this.getAnswerFromProp("13.1_2_RegularOrLowCarbon")
    ) {
      return true;
    } else {
      return false;
    }
  }

  findIfAnswerIsSelected(option, value) {
    if (
      this.props.answers &&
      this.props.answers.find(answer => answer.survey_question === option) &&
      this.props.answers.find(answer => answer.survey_question === option)
        .answer === value
    ) {
      return true;
    } else {
      return false;
    }
  }

  renderPicturesQuestions(options) {
    return (
      <>
        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "19px",
            lineHeight: "25px",
            color: "#fff",
            paddingTop: "50px"
          }}
        >
          {options.title}
        </div>

        <div
          style={{
            width: "100%",
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {options.answers.map(option => (
            <div
              onClick={e =>
                this.props.addAnswerToSurvey({
                  description: option.value,
                  survey_question: options.survey_question,
                  answer: option.value,
                  type: "single_answer",
                  groupAnswer: false,
                  othersValue: false,
                  plates: false
                })
              }
              className={
                "picture-option " +
                (this.findIfAnswerIsSelected(
                  options.survey_question,
                  option.value
                )
                  ? "picture-option-selected"
                  : null)
              }
              style={{
                // display: "inline-block",
                backgroundImage: "url(" + option.image + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
                height: "182px",
                width: "168px",
                margin: this.props.matches.large ? "50px" : "5px"
              }}
            ></div>
          ))}
        </div>
      </>
    );
  }
  renderGroupAnswer(title, answers) {
    return (
      <>
        <div style={radioTitleStyle}>{title}</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: "40%",
            paddingTop: "20px",
            paddingBottom: "20px"
          }}
        >
          <div>Not important</div>
          <div>Neutral</div>
          <div>Very important</div>
        </div>
        <div>
          {answers.map(answer => (
            <div style={groupAnswers}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: "70px",
                  paddingLeft: "30px",
                  color: "#fff",
                  width: "500px"
                }}
              >
                {answer.choice_description}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",

                  color: "#fff",
                  width: "700px"
                }}
              >
                <Radio.Group
                  options={groupsOptions_1To5}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                  onChange={e => {
                    this.props.addAnswerToSurvey({
                      description: answer.group_question_description,
                      survey_question: answer.survey_question,
                      answer: e.target.value,
                      type: "group_answer",
                      groupAnswer: "6",
                      othersValue: false,
                      plates: false
                    });

                    //this.setState({ [answer.survey_question]: e.target.value }); //for render reasons
                  }}
                  value={this.getAnswerFromProp(answer.survey_question)}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        <div
          style={{
            //backgroundImage: "url(" + bgimage + ")",
            background: "#2C7873",

            height: "auto",
            width: "100%",
            padding: this.props.matches.large ? " 60px" : "80px 20px",
            textAlign:
              this.props.selectedLanguage === "ar_AE" ? "right" : "left",
            direction: this.props.selectedLanguage === "ar_AE" ? "rtl" : null
            // paddingTop: this.props.matches.large ? "60px" : "80px"
          }}
        >
          <div
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "30px",
              lineHeight: "45px",
              color: "#fff"
            }}
          >
            {
              dicionary[this.props.selectedLanguage]
                .whats_the_carbon_of_your_food
            }
          </div>
          <div
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "20px",
              lineHeight: "30px",
              paddingTop: "15px",
              color: "#fff"
            }}
          >
            {dicionary[this.props.selectedLanguage].page3_of3}
          </div>
          <div
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: this.props.matches.large ? "30px" : "15px",
              lineHeight: "50px",
              padding: "15px",
              color: "#fff"
            }}
          >
            {dicionary[this.props.selectedLanguage].seccion3_whats_your}
          </div>

          <div>
            <div style={radioTitleStyle}>
              {
                dicionary[this.props.selectedLanguage]
                  .where_do_you_mostly_buy_your_food
              }
            </div>
            {/* 1. What is your gender? */}
            <Radio.Group
              style={answersMarginLeft}
              onChange={e =>
                this.props.addAnswerToSurvey({
                  description: "Where do you mostly buy your food?",
                  survey_question: "7_2_where_to_buy",
                  answer: e.target.value,
                  type: "single_answer",
                  groupAnswer: false,
                  othersValue: false,
                  plates: false
                })
              }
              value={this.getAnswerFromProp("7_2_where_to_buy")}
            >
              <Radio style={radioOptionsStyle} value={"Supermarket"}>
                {dicionary[this.props.selectedLanguage].supermarket}
              </Radio>
              <Radio style={radioOptionsStyle} value={"Farmers’ market"}>
                {dicionary[this.props.selectedLanguage].farmers_market}
              </Radio>
              <Radio style={radioOptionsStyle} value={"Online"}>
                {dicionary[this.props.selectedLanguage].online}
              </Radio>
              <Radio style={radioOptionsStyle} value={"Other"}>
                {dicionary[this.props.selectedLanguage].other}
              </Radio>
              <Radio style={radioOptionsStyle} value={"I don´t buy food"}>
                {dicionary[this.props.selectedLanguage].i_dont_buy_food}
              </Radio>
            </Radio.Group>
          </div>

          <div style={radioTitleStyle}>
            {
              dicionary[this.props.selectedLanguage][
                "Which one of these products would you buy"
              ]
            }
          </div>

          <div
            style={{
              width: "100%",
              height: "300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                backgroundImage: "url(" + Img_fruits_on_market + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
                height: "300px",
                width: "600px"
              }}
            ></div>
          </div>

          <div style={radioSubTitleStyle}>
            {dicionary[this.props.selectedLanguage]["Imported or national?"]}
          </div>

          {this.renderOptionButtons(
            "12.1_1_importedOrNational", //question ID
            dicionary[this.props.selectedLanguage]["(same price)"], //first text
            dicionary[this.props.selectedLanguage]["Imported $ 1.00"], //button1 text
            "Imported", //button1 value to send as an answer
            dicionary[this.props.selectedLanguage]["or"],
            dicionary[this.props.selectedLanguage]["National $ 1.00"], //button2 text
            "National" //button2 value to send as an answer
          )}

          {this.renderOptionButtons(
            //same comments as above
            "12.1_2_importedOrNational", //question ID
            dicionary[this.props.selectedLanguage]["(premium)"],
            dicionary[this.props.selectedLanguage]["Imported $ 1.00"],
            "Imported", //button1 value to send as an answer
            dicionary[this.props.selectedLanguage]["or"],
            dicionary[this.props.selectedLanguage]["National $ 1.50"],
            "National" //button2 value to send as an answer
          )}
          <div style={radioSubTitleStyle}>
            {dicionary[this.props.selectedLanguage]["Non-organic or organic"]}
          </div>
          {this.renderOptionButtons(
            //same comments as above
            "12.2_1_nonOrganicOrOrganic",
            dicionary[this.props.selectedLanguage]["(same price)"], //first text
            dicionary[this.props.selectedLanguage]["Non-organic $ 1.00"], //button1 text
            "Non-organic",
            dicionary[this.props.selectedLanguage]["or"],
            dicionary[this.props.selectedLanguage]["Organic $ 1.00"],
            "Organic"
          )}
          {this.renderOptionButtons(
            //same comments as above
            "12.2_2_nonOrganicOrOrganic",
            dicionary[this.props.selectedLanguage]["(premium)"],
            dicionary[this.props.selectedLanguage]["Non-organic $ 1.00"],
            "Non-organic",
            dicionary[this.props.selectedLanguage]["or"],
            dicionary[this.props.selectedLanguage]["Organic $ 1.50"],
            "Organic"
          )}
          {/*  <div style={radioTitleStyle}>
            11. Which one of these products would you buy?
          </div>

           {this.renderPicturesQuestions({
            title: "11.1. Imported or national?",
            survey_question: "9.1_2_importedOrNational",
            answers: [
              {
                value: "Imported",
                image: Img_9_1_A
              },
              {
                value: "National",
                image: Img_9_1_B
              }
            ]
          })}

          {this.renderPicturesQuestions({
            title: "11.2 Imported or national?",
            survey_question: "9.2_2_importedOrNational",
            answers: [
              {
                value: "Imported",
                image: Img_9_2_A
              },
              {
                value: "National",
                image: Img_9_2_B
              }
            ]
          })}

          <div style={radioTitleStyle}>
            12. Which one of these products would you buy?
          </div>
          {this.renderPicturesQuestions({
            title: "12.1. Non-organic or organic? ",
            survey_question: "10.1_2_nonOrganicOrOrganic",
            answers: [
              {
                value: "Non-organic",
                image: Img_10_1_A
              },
              {
                value: "Organic",
                image: Img_10_1_B
              }
            ]
          })}
          {this.renderPicturesQuestions({
            title: "12.2. Non-organic or organic?",
            survey_question: "10.2_2_nonOrganicOrOrganic",
            answers: [
              {
                value: "Non-organic",
                image: Img_10_2_A
              },
              {
                value: "Organic",
                image: Img_10_2_B
              }
            ]
          })} */}
          <div style={radioTitleStyle}>
            {
              dicionary[this.props.selectedLanguage][
                "There are new innovative ways of growing..."
              ]
            }
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",

              width: "100%",
              height: "300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                backgroundImage: "url(" + Img_11 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
                height: "300px",
                width: "600px"
              }}
            ></div>
            {this.props.selectedLanguage === "ar_AE" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "600px",
                  color: "#fff"
                }}
              >
                <div>{"الزراعية في المحميات المائية"}</div>
                <div>{"الزراعة الحقلية"}</div>
              </div>
            ) : null}
          </div>

          <div style={radioTitleStyle}>
            {
              dicionary[this.props.selectedLanguage][
                "Which one of these products would you buy"
              ]
            }
          </div>
          <div style={radioSubTitleStyle}>
            {dicionary[this.props.selectedLanguage]["Regular or low-carbon?"]}
          </div>
          {this.renderOptionButtons(
            //same comments as above
            "13.1_1_RegularOrLowCarbon",
            dicionary[this.props.selectedLanguage]["(same price)"],
            dicionary[this.props.selectedLanguage]["Regular $ 1.00"],
            "Regular",
            dicionary[this.props.selectedLanguage]["or"],
            dicionary[this.props.selectedLanguage]["Low-Carbon $ 1.00"],
            "Low-Carbon"
          )}
          {this.renderOptionButtons(
            //same comments as above
            "13.1_2_RegularOrLowCarbon",
            dicionary[this.props.selectedLanguage]["(premium)"],
            dicionary[this.props.selectedLanguage]["Regular $ 1.00"],
            "Regular",
            dicionary[this.props.selectedLanguage]["or"],
            dicionary[this.props.selectedLanguage]["Low-Carbon $ 1.50"],
            "Low-Carbon"
          )}
          {/* {this.renderPicturesQuestions({
            title: "13.1. Regular or low-carbon? ",
            survey_question: "10.1_2_nonOrganicOrOrganic",
            answers: [
              {
                value: "Regular",
                image: Img_13_1_A
              },
              {
                value: "Low_Carbon",
                image: Img_13_1_B
              }
            ]
          })}
          {this.renderPicturesQuestions({
            title: "13.2. Regular or low-carbon?  ",
            survey_question: "10.2_2_nonOrganicOrOrganic",
            answers: [
              {
                value: "Imported",
                image: Img_13_2_A
              },
              {
                value: "National",
                image: Img_13_2_B
              }
            ]
          })} */}

          {/* 
          <div style={radioTitleStyle}>
            12. Would you buy hydroponic vegetables?
          </div>
          <div
            style={{
              width: "100%",
              height: "300px",
              ...answersMarginLeft
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center"
            }}
          >
            <div style={{ width: "600px" }}>
              <Slider
                dots={true}
                max={3}
                min={0}
                step={1}
                onChange={value =>
                  this.props.addAnswerToSurvey({
                    description: "12. Would you buy hydroponic vegetables?",
                    survey_question: "12_2_buy_hidroponics",
                    answer: value,
                    type: "single_answer",
                    groupAnswer: false,
                    othersValue: false,
                    plates: false
                  })
                }
                value={
                  this.getAnswerFromProp("12_2_buy_hidroponics")
                    ? this.getAnswerFromProp("12_2_buy_hidroponics")
                    : 0
                }
              />
            </div>
            <br />
       
            <div
              style={{
                backgroundImage: "url(" + Img_12 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
                height: "100px",
                width: "600px"
              }}
            ></div>
          </div> */}

          <div
            style={{
              paddingTop: "50px",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center"
            }}
          >
            <Link to="/Survey_Page_2_plates">
              <div
                style={{
                  textAlign: "center",
                  width: "171px",
                  height: "63px",
                  background: "#2C7873",
                  border: "#fff",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderRadius: "4px",
                  color: "#fff",
                  textTransform: "uppercase",
                  padding: "20px",
                  margin: "5px"
                }}
              >
                {dicionary[this.props.selectedLanguage]["previous"]}
              </div>
            </Link>
            <Link
              to="/survey_page_final"
              onClick={e =>
                this.validateAnswers() ? null : e.preventDefault()
              }
            >
              <div
                style={{
                  textAlign: "center",
                  width: "171px",
                  height: "63px",
                  border: "#fff",
                  background: this.validateAnswers() ? "#fff" : "#aaa",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderRadius: "4px",
                  color: "#2C7873",
                  textTransform: "uppercase",
                  padding: "20px",
                  margin: "5px"
                }}
                onClick={async () => {
                  if (this.validateAnswers()) {
                    let localSurvey = {
                      date: new Date(Date.now()),
                      answers: this.props.answers,
                      foodGroups: this.props.generalSurveyData.foodGroups
                    };
                    //FIX to the empty plate problem : delete all plate info - no longer needed
                    localSurvey.answers.forEach(
                      answer => (answer.plates = null)
                    );
                    console.log("sending: ", localSurvey);
                    await sendSurvey(localSurvey);

                    // this.props.setGeneralSurveyData(
                    //   "foodGroups",
                    //   this.state.foodGroupsCountingsPerYear
                    // );
                  } else {
                    message.error("Problems submitting the survey");
                  }
                }}
              >
                {dicionary[this.props.selectedLanguage]["Finish"]}
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  }

  /*
 {this.renderOptionButtons(
            "9.1_2_importedOrNational", //question ID
            "(same price)", //first text
            "Imported $ 1.00", //button1 text
            "Imported", //button1 value to send as an answer
            "OR", //or duh
            "National $ 1.00", //button2 text
            "National" //button2 value to send as an answer
          )}
  */

  renderOptionButtons(
    questionID,
    text1,
    textOfButton1,
    valueButton1,
    text2,
    textOfButton2,
    valueButton2
  ) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          padding: "20px",
          flexWrap: "wrap"
        }}
      >
        <div
          style={{
            display: "flex",
            width: "120px",
            height: "69px",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            fontFamily: "Roboto",
            fontStyle: "italic",
            fontWeight: "300",
            fontSize: "17px"
          }}
        >
          {text1}
        </div>
        <div
          style={{
            width: "230px",
            height: "69px",
            background: this.findIfAnswerIsSelected(questionID, valueButton1)
              ? "#fff6"
              : "#004445",
            border: this.findIfAnswerIsSelected(questionID, valueButton1)
              ? "1px solid #004445"
              : "1px solid #fff",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "20px",
            flexWrap: "no-wrap"
          }}
          onClick={e =>
            this.props.addAnswerToSurvey({
              description: valueButton1,
              survey_question: questionID,
              answer: valueButton1,
              type: "single_answer",
              groupAnswer: false,
              othersValue: false,
              plates: false
            })
          }
        >
          {" "}
          {textOfButton1}
        </div>{" "}
        <div
          style={{
            display: "flex",
            width: "50px",
            height: "69px",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            fontFamily: "Roboto",
            fontStyle: "italic",
            fontWeight: "300",
            fontSize: "17px"
          }}
        >
          {text2}
        </div>
        <div
          style={{
            width: "230px",
            height: "69px",
            background: this.findIfAnswerIsSelected(questionID, valueButton2)
              ? "#fff6"
              : "#004445",
            border: this.findIfAnswerIsSelected(questionID, valueButton2)
              ? "1px solid #004445"
              : "1px solid #fff",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "20px"
          }}
          onClick={e =>
            this.props.addAnswerToSurvey({
              description: valueButton2,
              survey_question: questionID,
              answer: valueButton2,
              type: "single_answer",
              groupAnswer: false,
              othersValue: false,
              plates: false
            })
          }
        >
          {textOfButton2}
        </div>{" "}
        <div
          style={{
            display: "flex",
            width: "54px",
            height: "69px",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            fontFamily: "Roboto",
            fontStyle: "italic",
            fontWeight: "300",
            fontSize: "17px"
          }}
        >
          {dicionary[this.props.selectedLanguage].question_mark}
        </div>
      </div>
    );
  }
  handleChange = value => {
    console.log("handleChange - value", value);
    this.setState({ value });
  };
}

export default Survey_Page_2;
