import React, { Component } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

import "../styles/App.css";
import "../styles/Hero.css";
import "../styles/header.css";
import tryHarvest_Download from "../img/new/ourWork/tryHarvest_Download.svg";
import tryHarvest_Watch from "../img/new/ourWork/tryHarvest_Watch.svg";
import tryHarvesText from "../img/new/ourWork/tryHarvesText.svg";
import harvest from "../img/new/harvest.png";
import ourWork_harvest from "../img/new/ourWork_harvest.svg";

import Footer from "../components/footer";

// import SelectCity from "../SelectCity";
// import AboutResearch from "../AboutResearch";
// import Assessment from "../Assessment";
// import MainForm from "../MainForm";
// import TryHarvest from "../TryHarvest";
// import Footer from "../components/footer";
import titleSVG from "../img/new/harvest/title.svg";
import TryHarvest from "../TryHarvest";
class Harvest extends Component {
  constructor(props) {
    super(props);

    this.scrollTo = this.scrollTo.bind(this);
  }

  componentDidMount() {}
  scrollTo(item) {
    console.log(item);
    window.scrollTo({
      top: document.getElementById(item).getBoundingClientRect().bottom,
      behavior: "smooth"
    });
  }

  renderYoutube(youtubeID, title, text) {
    return (
      <div style={{ padding: 20, marginBottom: 50 }}>
        <div
          className="video"
          style={{
            position: "relative",
            paddingBottom: "56.25%" /* 16:9 */,
            paddingTop: 25,
            height: 0
          }}
        >
          <iframe
            title={youtubeID}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
            src={"https://www.youtube.com/embed/" + youtubeID}
            allowFullScreen
            frameBorder="0"
          />
        </div>
        <div
          style={{
            fontFamily: "Roboto",
            fontSize: "30px",
            paddingTop: 30,
            color: "black"
          }}
        >
          {title}
          <br />
        </div>

        <div style={{ fontFamily: "Roboto", fontSize: "17px" }}>{text}</div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ backgroundColor: "#F6F6F6" }}>
        <Row type="flex" justify="center">
          <Col xs={{ span: 24 }} lg={{ span: 24, offset: 0 }}>
            <div className="harvest_container">
              <img
                className="harvest_text"
                //onClick={() => this.toggleLeft()}
                src={ourWork_harvest}
                alt="ourWork_harvest"
              />
            </div>
            <div style={{ width: "100%" }}>
              <img
                id="harvest_pic"
                style={{ width: "100%" }}
                //onClick={() => this.toggleLeft()}
                src={harvest}
                alt="harvest"
              />
            </div>
          </Col>
          <Col xs={{ span: 22 }} lg={{ span: 24, offset: 0 }}>
            <div className="try_harvest_container">
              <img
                className="tryHarvesText"
                //style={{ width: "100%" }}
                //onClick={() => this.toggleLeft()}
                src={tryHarvesText}
                alt="tryHarvesText"
              />
              <div style={{ display: "inline" }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  rel="noopener noreferrer"
                  href={
                    "https://gmail.us20.list-manage.com/subscribe?u=2a6b2b3cbcd0647d2a73f3d8b&id=2d8999336c"
                  }
                >
                  <img
                    className="tryHarvest_Download"
                    //style={{ width: "100%" }}
                    //onClick={() => this.toggleLeft()}
                    src={tryHarvest_Download}
                    alt="tryHarvest_Download"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  rel="noopener noreferrer"
                  href={"https://www.youtube.com/watch?v=6R6js9i5Cu0"}
                >
                  <img
                    className="tryHarvest_Watch"
                    //style={{ width: "100%" }}
                    //onClick={() => this.toggleLeft()}
                    src={tryHarvest_Watch}
                    alt="tryHarvest_Watch"
                  />
                </a>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 8, offset: 0 }}>
            <img
              style={{ width: "100%", marginTop: "60px", marginBottom: "60px" }}
              //onClick={() => this.toggleLeft()}
              src={titleSVG}
              alt="titleSVG"
            />
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={{ span: 20 }} lg={{ span: 7 }}>
            {this.renderYoutube(
              "JrJKKmYckkw",
              "Demo",
              <div>
                Demo Duration 10:51 | Tutorial 04 <br />
                <br />
                This tutorial shows you how to set and run a simulation in
                HARVEST. Tutorials 01, 02 and 03 provide you some background and
                guidance on the preliminary steps that you have to complete
                before running the simulation.
              </div>
            )}
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={{ span: 20 }} lg={{ span: 7, offset: 0 }}>
            {this.renderYoutube(
              "6R6js9i5Cu0",
              "Introduction",
              <div>
                Duration 3:40 | Tutorial 01 <br />
                <br />
                HARVEST models Controlled-Environment Agriculture, i.e.,
                cultivation systems where the environment is controlled to
                extend crop growth period. This tutorial presents the tool and
                the type of farms that it considers, as well as its simulation
              </div>
            )}
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 7, offset: 0 }}>
            {this.renderYoutube(
              "sukxq2BtBKY",
              "Farm templates",
              <div>
                Duration 6:35 | Tutorial 02 <br />
                <br />
                This tutorial introduces the HARVEST panel, explains crop
                classification, and goes through the components of farm template
                libraries.
              </div>
            )}
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 7, offset: 0 }}>
            {this.renderYoutube(
              "GtFQNWsL3dY",
              "Urban foodprints",
              <div>
                Duration 4:46 | Tutorial 03 <br />
                <br />
                This tutorial goes through the contents of the Urban Foodprint
                file and shows you where to get the data that you need to build
                your foodprint. You can use the{" "}
                <strong>
                  <Link
                    to={{
                      pathname: "/getyourfoodprint"
                    }}
                  >
                    FOODprint generator
                  </Link>
                </strong>{" "}
                to generate the JSON file for your city.
              </div>
            )}
          </Col>
        </Row>

        <TryHarvest />
        <Footer />
      </div>
    );
  }
}

export default Harvest;
