import React, { Component } from "react";
import { Row, Col } from "antd";
//import "./styles/App.css";
import "./styles/AboutResearch.css";

import tryHarvest_ALL from "./img/new/home_bottom.svg";
import download_button from "./img/new/home/download.svg";
import watchTutorial from "./img/new/home/watchTutorial.svg";

export default class TryHarvest extends Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#353535",
          height: "auto",
          textAlign: "center"
        }}
      >
        <Row type="flex" justify="center">
          <Col xs={{ span: 20 }} lg={{ span: 13, offset: 0 }}>
            <img
              style={{ width: "100%", marginTop: "60px", marginBottom: "60px" }}
              //onClick={() => this.toggleLeft()}
              src={tryHarvest_ALL}
              alt="tryHarvest_ALL"
            />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://gmail.us20.list-manage.com/subscribe?u=2a6b2b3cbcd0647d2a73f3d8b&id=2d8999336c"
              }
            >
              <img
                style={{
                  // width: "50%"
                  marginBottom: "50px"
                }}
                //onClick={() => this.toggleLeft()}
                src={download_button}
                alt="download_button"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://www.youtube.com/watch?v=6R6js9i5Cu0"}
            >
              <img
                style={{
                  // width: "50%",
                  marginLeft: "0px",
                  marginBottom: "50px"
                }}
                //onClick={() => this.toggleLeft()}
                src={watchTutorial}
                alt="watchTutorial"
              />
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}
