import React, { Component } from "react";
import { Row, Col } from "antd";
//import "./styles/App.css";
import "../styles/new_page/ourWork_root.css";
import { Link } from "react-router-dom";
//import UFP_Dashboard from "../img/UFP_Dashboard.svg";

import icon_harvest from "../img/refactoredVersion/ourWork/icon_harvest.svg";
import icon_ongoing from "../img/refactoredVersion/ourWork/icon_ongoing.svg";
import icon_publications from "../img/refactoredVersion/ourWork/icon_publications.svg";
import icon_teaching from "../img/refactoredVersion/ourWork/icon_teaching.svg";
import read_more_button from "../img/refactoredVersion/ourWork/read_more_button.svg";

import Footer from "../components/footer";

//import readMoreButton from "../img/new/read_more_button.svg";

export default class OurWork extends Component {
  // constructor(props) {
  //   super(props);
  // }
  scrollTo(item) {
    console.log(item);
    window.scrollTo({
      top: document.getElementById(item).getBoundingClientRect().bottom,
      behavior: "smooth"
    });
  }
  componentDidMount() {
    console.log(this.props);
    // if (this.props.location.state && this.props.location.state.scrollTo) {
    //   this.scrollTo(this.props.location.state.scrollTo);
    // }
  }

  renderEachBlock(icon, title, text, buttonLink) {
    return (
      <div
        style={{
          position: "relative",
          width: "280px",
          height: "550px",
          color: "white",
          backgroundColor: "#303030",
          textAlign: "center",
          marginBottom: "20px",
          padding: "20px",
          margin: "10px"
        }}
      >
        <img
          style={{ width: "55%" }}
          //className="graphsDiv"
          //onClick={() => this.toggleLeft()}
          src={icon}
          alt="icon"
        />

        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "22px",
            lineHeight: "177.04%",
            textAlign: "center",
            marginBottom: "30px"
          }}
        >
          {title}
        </div>
        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "14px",
            lineHeight: "177.04%",
            color: "white",
            marginBottom: "30px"
          }}
        >
          {text}
        </div>
        <Link to={buttonLink}>
          <img
            style={{
              position: "absolute",
              bottom: "40px",
              width: "150px",
              left: "75px"
            }}
            //className="graphsDiv"
            //onClick={() => this.toggleLeft()}
            src={read_more_button}
            alt="read_more_button"
          />
        </Link>
      </div>
    );
  }

  render() {
    return (
      <>
        <div style={{ backgroundColor: "#F6F6F6", paddingBottom: "60px" }}>
          <div className="full_about">Our Work </div>

          <Row type="flex" justify="space-around">
            <Col xs={{ span: 20 }} lg={{ span: 6, offset: 0 }}>
              {this.renderEachBlock(
                icon_publications,
                "Publications",
                'Our research was published in international peer-reviewed journals, such as the “Journal of Cleaner Production”, "Cities" and "Global Food Security" as well as in conference proceedings.',
                "/publications"
              )}
            </Col>{" "}
            <Col xs={{ span: 20 }} lg={{ span: 6, offset: 0 }}>
              {this.renderEachBlock(
                icon_teaching,
                "Teaching",
                "At MIT, we tought the research seminar 4s43. Food for Thought - Exploring the Impact of our Diets on the Health of our Planet (Spring 2019).",
                "/teaching"
              )}
            </Col>{" "}
            <Col xs={{ span: 20 }} lg={{ span: 6, offset: 0 }}>
              {this.renderEachBlock(
                icon_harvest,
                "Harvest",
                "We developed HARVEST, the first CAD-embedded tool for urban food production simulation that works as a plugin for the Urban Modeling Interface (UMI).",
                "/harvest"
              )}
            </Col>{" "}
            <Col xs={{ span: 20 }} lg={{ span: 6, offset: 0 }}>
              {this.renderEachBlock(
                icon_ongoing,
                "Ongoing",
                "We are developing a holistic model for sustainable urban food systems through enhanced urban data analytics.",
                "/survey_intro"
              )}
            </Col>
          </Row>
          {/* <Col xs={{ span: 24 }} lg={{ span: 24, offset: 0 }}>
            <img
              id="publications"
              style={{ width: "100%" }}
              //onClick={() => this.toggleLeft()}
              src={ourWork_text}
              alt="ourWork_text"
            />
          </Col> */}
        </div>
        <Footer />
      </>
    );
  }
}
