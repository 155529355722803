import { Button, Checkbox, Col, message, Modal, Row, Select } from "antd";
import React, { Component } from "react";
import Media from "react-media";
import { Link } from "react-router-dom";
import { Bar, BarChart, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import { dicionary } from "../../dicionary";
//import Plates from "./plates_screen/Plates";
import FoodTray from "./foodtray_screen/Foodtray";
import { ingredient_list } from "./foodtray_screen/ingredients_explanation.js";
import Img_13_2 from "./images/question_pictures/13_delete.svg";
import Img_13_1 from "./images/question_pictures/13_edit.svg";
import bigArrow from "./images/question_pictures/big_arrow.svg";
import "./survey_css.css";

const { Option } = Select;
const { confirm } = Modal;
const titleStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "19px",
  lineHeight: "25px",
  color: "#fff",
};
class Survey_Page_3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLanguage: "us_US",
      platesVisible: false,
      foodGroupsTotal: {},
      foodGroupsCountingsPerYear: {},
      noMeals: {},
      foodSpecificationVisible: false,
      recomendedDailykCal: null,
    };

    this.showModalPlates = this.showModalPlates.bind(this);
    this.addToFoodGroupCountings = this.addToFoodGroupCountings.bind(this);
  }

  componentDidMount() {
    //   console.log("this.props.mealTotals:", this.props.mealTotals);
    if (this.props.selectedLanguage) {
      this.setState({ selectedLanguage: this.props.selectedLanguage });
    } else {
      this.setState({ selectedLanguage: "us_US" });
    }
  }
  // languageChanged(value) {
  //   //console.log(`selected ${value}`);
  //   this.setState({ selectedLanguage: value });
  // }
  getAnswerFromProp(question) {
    let answer;
    let result;
    if (
      this.props.answers &&
      this.props.answers.find((answer) => answer.survey_question === question)
    ) {
      result = this.props.answers.find(
        (answer) => answer.survey_question === question
      ).answer;
    } else {
      result = null;
    }
    //console.log("result", result);

    return result;
  }

  addToFoodGroupCountings(foodgroups_, meal) {
    let foodgroups = foodgroups_;

    let foodGroupsTotal = this.state.foodGroupsTotal;
    //console.log("addToFoodGroupCountings - foodgroups",foodgroups)
    //avoiding nulls
    Object.keys(foodgroups).forEach((group) => {
      // console.log("analyzing group:group , group.co2",foodgroups.group,foodgroups.group.co2)
      if (!foodgroups[group].co2) {
        foodgroups[group].co2 = 0;
      }
      if (!foodgroups[group].calories) {
        foodgroups[group].calories = 0;
      }
    });

    foodGroupsTotal[meal] = foodgroups;
    if (foodGroupsTotal) {
      //calculating PER YEAR
      let foodGroupsCountingsPerYear = {
        sugar_fat: { co2: null, calories: null },
        grain: { co2: null, calories: null },
        dairy_eggs: { co2: null, calories: null },
        other: { co2: null, calories: null },
        produce: { co2: null, calories: null },
        meat: { co2: null, calories: null },
      };

      Object.keys(foodGroupsTotal).forEach((meal) => {
        foodGroupsCountingsPerYear["sugar_fat"].co2 +=
          foodGroupsTotal[meal].sugar_fat.co2;
        foodGroupsCountingsPerYear["grain"].co2 +=
          foodGroupsTotal[meal].grain.co2;
        foodGroupsCountingsPerYear["dairy_eggs"].co2 +=
          foodGroupsTotal[meal].dairy_eggs.co2;
        foodGroupsCountingsPerYear["other"].co2 +=
          foodGroupsTotal[meal].other.co2;
        foodGroupsCountingsPerYear["produce"].co2 +=
          foodGroupsTotal[meal].produce.co2;
        foodGroupsCountingsPerYear["meat"].co2 +=
          foodGroupsTotal[meal].meat.co2;

        foodGroupsCountingsPerYear["sugar_fat"].calories +=
          foodGroupsTotal[meal].sugar_fat.calories;
        foodGroupsCountingsPerYear["grain"].calories +=
          foodGroupsTotal[meal].grain.calories;
        foodGroupsCountingsPerYear["other"].calories +=
          foodGroupsTotal[meal].other.calories;
        foodGroupsCountingsPerYear["dairy_eggs"].calories +=
          foodGroupsTotal[meal].dairy_eggs.calories;
        foodGroupsCountingsPerYear["produce"].calories +=
          foodGroupsTotal[meal].produce.calories;
        foodGroupsCountingsPerYear["meat"].calories +=
          foodGroupsTotal[meal].meat.calories;

        //console.log("foodGroupsCountingsPerYear: ", foodGroupsCountingsPerYear);
        this.props.setGeneralSurveyData(
          "foodGroups",
          foodGroupsCountingsPerYear
        );
        //  this.setState({ foodGroupsCountingsPerYear });
      });
    }
  }

  getPlateFromProp(question) {
    let answer;
    let result;
    if (
      this.props.answers &&
      this.props.answers.find((answer) => answer.survey_question === question)
    ) {
      result = this.props.answers.find(
        (answer) => answer.survey_question === question
      ).plates;
    } else {
      result = null;
    }
    //console.log("result", result);

    return result;
  }

  showModalPlates() {
    let { meal, color, survey_question, foodQuantity } = this.state;

    return (
      <Modal
        width="98vw"
        zIndex={100}
        style={{
          top: "2vh",
          textAlign: "center",
          zIndex: 101,
          maxWidth: "1200px",
          width: "98vw",
          height: "2000px",
          //overflowY: "scroll"
        }}
        buttonProps={{ size: "small" }}
        // bodyStyle={{
        //   // minHeight: "90vh",
        //   height: "100vh",
        //   // width: "100%",
        //   maxWidth: "1200px",
        //   overflowY: "scroll"
        // }}
        //maskStyle={{ overflowY: "scroll" }}
        visible={this.state.platesVisible}
        footer={[
          <Button
            key="food"
            icon="info"
            type="primary"
            onClick={() => this.setFoodSpecification(true)}
          >
            {
              dicionary[this.props.selectedLanguage][
                "What’s in the prepared foods"
              ]
            }
          </Button>,
          <Button
            key="Close"
            // loading={loading}
            onClick={() => this.setState({ platesVisible: false })}
          >
            {dicionary[this.props.selectedLanguage].close}
          </Button>,
        ]}
        // onOk={() => this.setState({ platesVisible: false })}
        onCancel={() => this.setState({ platesVisible: false })}
        cancelButtonProps={{ disabled: true }}
        // footer={null}
      >
        <FoodTray
          selectedLanguage={this.props.selectedLanguage}
          answers={this.props.answers}
          addAnswerToSurvey={this.props.addAnswerToSurvey}
          mealInfo={{ meal: meal, foodQuantity: foodQuantity }}
          color={color}
          survey_question={survey_question}
          plateData={this.props.plateData}
          changePlateUX={this.props.changePlateUX}
          addToFoodGroupCountings={this.addToFoodGroupCountings}
          saveFoodtrayFullInfo={this.props.saveFoodtrayFullInfo}
          foodTrayFullInfo={this.props.foodTrayFullInfo}
          matches={this.props.matches}
          saveWeekdiferenceOrAnyday={this.props.saveWeekdiferenceOrAnyday}
          getFoodDayState={this.props.getFoodDayState}
          drinksChosen={this.props.drinksChosen}
          country={this.getAnswerFromProp("3A_1_country")}
          // country={"Portugal"}
        />
      </Modal>
    );
  }

  getRecomendedDailyCalories() {
    let dailyKCal = {
      Female: {
        "Under 18": {
          "Once a week or less": 1800,
          "1-3 times a week": 2000,
          "At least 5 times a week": 2400,
        },
        "19-24": {
          "Once a week or less": 2000,
          "1-3 times a week": 2200,
          "At least 5 times a week": 2400,
        },
        "25-34": {
          "Once a week or less": 1800,
          "1-3 times a week": 2000,
          "At least 5 times a week": 2400,
        },
        "35-49": {
          "Once a week or less": 1800,
          "1-3 times a week": 2000,
          "At least 5 times a week": 2200,
        },
        "50-64": {
          "Once a week or less": 1600,
          "1-3 times a week": 1800,
          "At least 5 times a week": 2200,
        },
        "65 +": {
          "Once a week or less": 1600,
          "1-3 times a week": 1800,
          "At least 5 times a week": 2000,
        },
      },
      Male: {
        "Under 18": {
          "Once a week or less": 2400,
          "1-3 times a week": 2800,
          "At least 5 times a week": 3200,
        },
        "19-24": {
          "Once a week or less": 2600,
          "1-3 times a week": 2800,
          "At least 5 times a week": 3000,
        },
        "25-34": {
          "Once a week or less": 2400,
          "1-3 times a week": 2600,
          "At least 5 times a week": 3000,
        },
        "35-49": {
          "Once a week or less": 2400,
          "1-3 times a week": 2600,
          "At least 5 times a week": 2800,
        },
        "50-64": {
          "Once a week or less": 2200,
          "1-3 times a week": 2400,
          "At least 5 times a week": 2600,
        },
        "65 +": {
          "Once a week or less": 2000,
          "1-3 times a week": 2200,
          "At least 5 times a week": 2600,
        },
      },
    };
    // console.log(
    //   "age,exercise,gender:",

    //   this.getAnswerFromProp("2_1_age"),
    //   this.getAnswerFromProp("4_1_exercise"),
    //   this.getAnswerFromProp("1_1_gender")
    // );
    if (
      this.getAnswerFromProp("2_1_age") &&
      this.getAnswerFromProp("4_1_exercise") &&
      this.getAnswerFromProp("1_1_gender")
    ) {
      let recomendedDailykCal = 0;
      recomendedDailykCal =
        dailyKCal[this.getAnswerFromProp("1_1_gender")][
          this.getAnswerFromProp("2_1_age")
        ][this.getAnswerFromProp("4_1_exercise")];
      // this.setState({
      //   recomendedDailykCal
      // });

      //  console.log("recomendedDailykCal:", recomendedDailykCal);
      return recomendedDailykCal;
    } else {
      return false;
    }
  }

  renderTotalCaloriesChart() {
    let dailyKCal = this.getRecomendedDailyCalories();
    const data = [
      {
        name: "Your calories",
        calories: this.countTotalCo2andCalories().totalCalories.toFixed(0),

        //   calories:
      },
    ];

    return (
      <div
        className="caloriesTotalFixed"
        style={{
          //zIndex: 10000,
          // display: "flex",
          position: "fixed",
          flexDirection: "column",
          top: this.props.matches.large ? "95px" : "30px",

          right: this.props.selectedLanguage === "ar_AE" ? null : "20px",

          left: this.props.selectedLanguage === "ar_AE" ? "20px" : null,

          //   bottom: this.props.matches.large ?  : null,
          //left: this.props.matches.large ? "50px" : null,
          //left: this.props.matches.large ? null : "12px",
          width: this.props.matches.large ? "390px" : "270px",
          height: this.props.matches.large ? "170px" : "170px",

          // display:
          //   this.state.foodButtonHover === food.name
          //     ? "flex"
          //     : "none",

          padding: "20px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          // backgroundPosition: "50% 50%",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          borderRadius: "8px",
          zIndex: 200,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            // alignItems: "left",
            justifyContent: "space-between",
          }}
        >
          {/* {this.props.matches.large ? (
            <div style={{ padding: "5px" }}>
              Your calories:
              {this.countTotalCo2andCalories().totalCalories.toFixed(0)}
            </div>
          ) : null}{" "} */}
          <div>
            {
              dicionary[this.props.selectedLanguage][
                "Share of daily recommended calories:"
              ]
            }
          </div>
          <div style={{ fontSize: "20px", fontWeight: 800 }}>
            {(
              (this.countTotalCo2andCalories().totalCalories / dailyKCal) *
              100
            ).toFixed(0)}
            %
          </div>
        </div>
        <div style={{ fontSize: 9, padding: 0 }}>
          {dicionary[this.props.selectedLanguage]["Recommended for you:"] +
            " " +
            dailyKCal +
            " " +
            dicionary[this.props.selectedLanguage].KCal}
        </div>
        <BarChart
          width={this.props.matches.large ? 400 : 280}
          height={this.props.matches.large ? 100 : 80}
          data={data}
          layout="vertical"
          style={{ marginLeft: "-50px" }}
        >
          <XAxis type="number" />
          <YAxis type="category" dataKey="KCal" />

          <Bar background label dataKey="calories" fill="#26C485" />
          <ReferenceLine
            isFront={true}
            alwaysShow={true}
            x={dailyKCal}
            //label="Your max"
            stroke="red"
            strokeDasharray="3 3"
          />
          <Tooltip />
        </BarChart>
      </div>
    );
  }

  renderMeal(meal, title, color, survey_question, hasTopLegends) {
    let builtString_SurveyQuestion = meal + "_page3";
    let foodQuantity;

    return (
      <div style={{ marginTop: "15px", color: "#fff" }}>
        <Media
          queries={{
            small: "(max-width: 599px)",
            medium: "(min-width: 600px) and (max-width: 999px)",
            large: "(min-width: 1000px)",
          }}
        >
          {(matches) => (
            <>
              {matches.small && null}
              {matches.medium && null}
              {matches.large && hasTopLegends && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "start",
                    marginTop: "0px",
                    marginBottom: "30px",
                    color: "#000",
                  }}
                >
                  {/* <div style={{ width: "25%" }}>Frequency</div> */}
                  <div style={{ width: "25%" }}> </div>
                  <div style={{ width: "13%" }}>
                    <div
                      className={"arrowAnimation"}
                      style={{
                        // display:""
                        position: "absolute",
                        backgroundImage: "url(" + bigArrow + ")",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "50% 50%",
                        height: "100px",
                        width: " 100px",
                        marginLeft: "-17px",
                        // left: "30%"
                      }}
                    ></div>
                    <strong>
                      {dicionary[this.props.selectedLanguage].fill_the_plate}
                    </strong>
                  </div>
                  <div style={{ width: "15%" }}>
                    {dicionary[this.props.selectedLanguage].calories}
                  </div>
                  <div style={{ width: "25%" }}>
                    {dicionary[this.props.selectedLanguage].carbon}
                  </div>
                  <div style={{ width: "5%" }}>
                    {dicionary[this.props.selectedLanguage].clear_all}
                  </div>
                </div>
              )}
            </>
          )}
        </Media>

        <div
          style={{
            // display: "flex",
            width: "100%",

            backgroundColor: color,
            // border:
            //   this.checkIfPlateIsNotFull(meal) && !this.state.noMeals[meal]
            //     ? "1px solid #DDB639"
            //     : null,
            padding: "10px",
          }}
        >
          <Row type="flex" justify="start" align="middle" gutter={[16, 24]}>
            <Col xs={{ span: 10 }} lg={{ span: 6 }}>
              <div style={titleStyle}>{title}</div>
              {/* <Select
                defaultValue="Medium"
                style={{ width: "100%" }}
                value={this.state[meal + "_foodQuantity"]}
                onChange={value => {
                  this.setState({
                    [meal + "_foodQuantity"]: value
                  });
                }}
              >
                <Option value="Small">Small</Option>
                <Option value="Medium">Medium</Option>
                <Option value="Large">Large</Option>
              </Select> */}
            </Col>
            <Col xs={{ span: 10 }} lg={{ span: 3 }}>
              <div
                onClick={() =>
                  this.setState({
                    meal: meal,
                    color: color,
                    survey_question: survey_question /*survey_question*/,
                    foodQuantity: this.state[meal + "_foodQuantity"],
                    platesVisible: true,
                  })
                }
                style={{
                  backgroundImage: "url(" + Img_13_1 + ")",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50% 50%",
                  height: "50px",
                  width: "50px",
                  cursor: "pointer",
                }}
              ></div>
            </Col>
            <Col xs={{ span: 10 }} lg={{ span: 3 }}>
              {this.props.mealTotals &&
              this.props.mealTotals.calories[meal] > 0 ? (
                <strong>
                  {this.props.mealTotals.calories[meal].toFixed(0)}
                </strong>
              ) : null}{" "}
              {dicionary[this.props.selectedLanguage].kcal_day}
            </Col>
            <Col xs={{ span: 10 }} lg={{ span: 7 }}>
              {this.props.mealTotals && this.props.mealTotals.co2[meal] > 0 ? (
                <strong>{this.props.mealTotals.co2[meal].toFixed(2)}</strong>
              ) : null}{" "}
              {dicionary[this.props.selectedLanguage].kgCO2eq_day}
            </Col>
            <Col xs={{ span: 10 }} lg={{ span: 3 }}>
              <div
                onClick={() => {
                  this.props.addAnswerToSurvey({
                    description: "Plate: " + meal,
                    survey_question: survey_question,
                    answer: "plates",
                    type: "plates",
                    groupAnswer: false,
                    othersValue: false,
                    plates: [],
                  });

                  this.props.saveFoodtrayFullInfo(meal, null);
                }}
                // clear button
                style={{
                  backgroundImage: "url(" + Img_13_2 + ")",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50% 50%",
                  height: "25px",
                  width: "25px",
                  cursor: "pointer",
                }}
              ></div>
            </Col>
            <Col xs={{ span: 10 }} lg={{ span: 2 }}>
              <div
                style={{
                  // width: "10%",
                  height: "50px",
                  fontSize: "5px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <Checkbox
                  onChange={(e) =>
                    this.iDontHaveThisMeal(meal, survey_question, e)
                  }
                  style={{ fontSize: "10px", color: "#fff", marginTop: "5px" }}
                >
                  <br />
                  {dicionary[this.props.selectedLanguage].dont_have_this_meal}
                </Checkbox>
              </div>
            </Col>
            {/* {this.checkIfPlateIsNotFull(meal) && !this.state.noMeals[meal] ? (
              <Col xs={{ span: 20 }} lg={{ span: 6 }}>
                {" "}
                <div style={{ color: "#DDB639", padding: "10px" }}>
                  Info: Plate is not fully filled
                </div>
              </Col>
            ) : null} */}
          </Row>
        </div>
      </div>
    );
  }

  checkIfAtLeastHasOneMeal() {
    let hasOneMealAtLeast = !!(
      (this.getPlateFromProp("breakfast_page_plates") &&
        this.getPlateFromProp("breakfast_page_plates")[0]) ||
      (this.getPlateFromProp("lunch_page3") &&
        this.getPlateFromProp("lunch_page3")[0]) ||
      (this.getPlateFromProp("dinner_page3") &&
        this.getPlateFromProp("dinner_page3")[0]) ||
      (this.getPlateFromProp("snacks_page3") &&
        this.getPlateFromProp("snacks_page3")[0])
    );
    return hasOneMealAtLeast;
  }

  checkIfAllFormIsReadyToSubmit() {
    //basically If we have a meal AND plate is full, Or one does not use that meal
    if (
      (this.getPlateFromProp("breakfast_page_plates") &&
        this.getPlateFromProp("breakfast_page_plates")[0]) || //&&
      //  !this.checkIfPlateIsNotFull("breakfast")
      this.state.noMeals["breakfast"]
    ) {
      if (
        (this.getPlateFromProp("lunch_page3") &&
          this.getPlateFromProp("lunch_page3")[0]) || //&&
        //    !this.checkIfPlateIsNotFull("lunch")
        this.state.noMeals["lunch"]
      ) {
        if (
          (this.getPlateFromProp("dinner_page3") &&
            this.getPlateFromProp("dinner_page3")[0]) ||
          //&&
          //  !this.checkIfPlateIsNotFull("dinner")
          this.state.noMeals["dinner"]
        ) {
          if (
            (this.getPlateFromProp("snacks_page3") &&
              this.getPlateFromProp("snacks_page3")[0]) ||
            //&&
            //    !this.checkIfPlateIsNotFull("snacks")
            this.state.noMeals["snacks"]
          ) {
            if (this.checkIfAtLeastHasOneMeal()) {
              //console.log("READY TO GO!");
              //this.setState({ readyToSubmitSurvey: true });
              return true;
            }
          }
        }
      }
    } else {
      //  this.setState({ readyToSubmitSurvey: false });
      return false;
    }
  }

  iDontHaveThisMeal(meal, survey_question, e) {
    //console.log(`checked = ${e.target.checked}`);

    let noMeals = this.state.noMeals;

    noMeals[meal] = e.target.checked;

    this.setState({
      noMeals,
    });

    if (e.target.checked) {
      //delete info about meal, so it not accounted
      this.props.addAnswerToSurvey({
        description: "Plate: " + meal,
        survey_question: survey_question,
        answer: "plates",
        type: "plates",
        groupAnswer: false,
        othersValue: false,
        plates: [],
      });
    }

    // this.props.addAnswerToSurvey({
    //   description: "Plate: " + meal,
    //   survey_question: survey_question,
    //   answer: "plates",
    //   type: "plates",
    //   groupAnswer: false,
    //   othersValue: false,
    //   plates: []
    // });
  }
  checkIfPlateIsNotFull(meal) {
    if (
      this.props.plateData &&
      this.props.plateData[meal] &&
      this.props.plateData[meal].find((slice) => slice.foodId === null)
    ) {
      return true;
    } else {
      return false;
    }
  }
  countTotalCo2andCalories() {
    let co2Array = [];
    let caloriesArray = [];
    //let totalCo2;
    //let totalCalories;

    // let totalCo2 =
    //   this.props.mealTotals.co2["breakfast"] +
    //   this.props.mealTotals.co2["lunch"] +
    //   this.props.mealTotals.co2["dinner"] +
    //   this.props.mealTotals.co2["snack"];
    // console.log(
    //   this.props.mealTotals.co2["breakfast"],
    //   this.props.mealTotals.co2["lunch"],
    //   this.props.mealTotals.co2["dinner"],
    //   this.props.mealTotals.co2["snack"],
    //   this.props.mealTotals.co2["breakfast"] +
    //     this.props.mealTotals.co2["lunch"] +
    //     this.props.mealTotals.co2["dinner"] +
    //     this.props.mealTotals.co2["snack"]
    // );
    // let totalCalories =
    //   this.props.mealTotals.calories["breakfast"] +
    //   this.props.mealTotals.calories["lunch"] +
    //   this.props.mealTotals.calories["dinner"] +
    //   this.props.mealTotals.calories["snack"];

    let totalCo2 = 0;
    Object.keys(this.props.mealTotals.co2).forEach((meal) => {
      if (this.props.mealTotals.co2[meal]) {
        totalCo2 += this.props.mealTotals.co2[meal];
      }
    });
    let totalCalories = 0;
    Object.keys(this.props.mealTotals.calories).forEach((meal) => {
      if (this.props.mealTotals.calories[meal]) {
        totalCalories += this.props.mealTotals.calories[meal];
      }
    });

    // if (
    //   this.getPlateFromProp("breakfast_page_plates") &&
    //   this.getPlateFromProp("breakfast_page_plates")[0]
    // ) {
    //   caloriesArray.push(
    //     this.getPlateFromProp("breakfast_page_plates")[0].calories.toFixed(0)
    //   );
    //   co2Array.push(
    //     this.getPlateFromProp("breakfast_page_plates")[0].co2.toFixed(2)
    //   );
    // }
    // if (
    //   this.getPlateFromProp("lunch_page3") &&
    //   this.getPlateFromProp("lunch_page3")[0]
    // ) {
    //   caloriesArray.push(
    //     this.getPlateFromProp("lunch_page3")[0].calories.toFixed(0)
    //   );
    //   co2Array.push(this.getPlateFromProp("lunch_page3")[0].co2.toFixed(2));
    // }
    // if (
    //   this.getPlateFromProp("dinner_page3") &&
    //   this.getPlateFromProp("dinner_page3")[0]
    // ) {
    //   caloriesArray.push(
    //     this.getPlateFromProp("dinner_page3")[0].calories.toFixed(0)
    //   );
    //   co2Array.push(this.getPlateFromProp("dinner_page3")[0].co2.toFixed(2));
    // }
    // if (
    //   this.getPlateFromProp("snacks_page3") &&
    //   this.getPlateFromProp("snacks_page3")[0]
    // ) {
    //   caloriesArray.push(
    //     this.getPlateFromProp("snacks_page3")[0].calories.toFixed(0)
    //   );
    //   co2Array.push(this.getPlateFromProp("snacks_page3")[0].co2.toFixed(2));
    // }
    // if (co2Array) {
    //   totalCo2 = co2Array.reduce((a, b) => Number(a) + Number(b), 0);
    // }
    // if (caloriesArray) {
    //   totalCalories = caloriesArray.reduce((a, b) => Number(a) + Number(b), 0);
    // }

    return { totalCalories, totalCo2 };
  }
  setFoodSpecification(foodSpecificationVisible) {
    this.setState({ foodSpecificationVisible: foodSpecificationVisible });
  }

  render() {
    //  //console.log("platesVisible", this.state.platesVisible);
    // console.log(
    //   "this.getPlateFromProp(this.props.survey_question)",
    //   this.getPlateFromProp(this.state.survey_question),
    //   "this.state.survey_question",
    //   this.state.survey_question
    // );

    //console.log("noMeals: ", this.state.noMeals);
    return (
      <>
        <div
          style={{
            //backgroundImage: "url(" + bgimage + ")",
            background: "#9AD2CB",
            height: "auto",
            width: "100%",
            padding: this.props.matches.large ? "60px" : "19px",
            paddingTop: this.props.matches.large ? "60px" : "80px",
            textAlign:
              this.props.selectedLanguage === "ar_AE" ? "right" : "left",
            direction: this.props.selectedLanguage === "ar_AE" ? "rtl" : null,
          }}
        >
          <div
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "30px",
              lineHeight: "45px",
              color: "#000",
            }}
          >
            {
              dicionary[this.props.selectedLanguage]
                .whats_the_carbon_of_your_food
            }
          </div>
          <div
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "20px",
              lineHeight: "30px",
              paddingTop: "15px",
              color: "#000",
            }}
          >
            {dicionary[this.props.selectedLanguage].page2from3}
          </div>
          <div
            style={{
              fontFamily: "Quicksand",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: "30px",
              lineHeight: "50px",
              padding: "15px",
              color: "#000",
            }}
          >
            {dicionary[this.props.selectedLanguage].seccion2_your_plate}
          </div>

          <div
            style={{
              width: "100%",
              //height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              //padding: "70px",
              // width: "600px",
              padding: "10px",
              paddingBottom: this.props.large ? "80px" : "28px",
            }}
          >
            <div
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "light",
                fontSize: "17px",
                lineHeight: "29px",
                color: "#000000",
              }}
            >
              {dicionary[this.props.selectedLanguage].what_do_you_eat}
              <br />
              {dicionary[this.props.selectedLanguage].this_section_is}
            </div>
          </div>

          <Row type="flex">
            <div
              style={{
                width: "100%",
                height: "80px",
                backgroundColor: "#F6F6F67F",
                padding: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "20px",
                  lineHeight: "29px",
                  color: "#000000",
                  width: "60%",
                  padding: "10%",
                }}
              >
                {dicionary[this.props.selectedLanguage].total}
              </div>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: this.props.matches.large ? "20px" : "10px",
                  lineHeight: "29px",
                  color: "#000000",
                  width: "20%",
                }}
              >
                {" "}
                <strong>
                  {this.countTotalCo2andCalories().totalCalories.toFixed(0)}
                </strong>{" "}
                {dicionary[this.props.selectedLanguage].kcal_day}
              </div>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: this.props.matches.large ? "20px" : "10px",
                  lineHeight: "29px",
                  color: "#000000",
                  width: "20%",
                }}
              >
                <strong>
                  {this.countTotalCo2andCalories().totalCo2.toFixed(2)}
                </strong>{" "}
                {dicionary[this.props.selectedLanguage].kgCO2eq_day}
              </div>
            </div>
          </Row>

          <div
            style={{
              marginTop: "40px",
              // borderStyle: "solid",
              // borderWidth: "1px",
              background: "#F6F6F67F",
              width: "100%",
              height: "auto",
              padding: "30px",
            }}
          >
            {this.renderMeal(
              "breakfast",

              dicionary[this.props.selectedLanguage].title_breakfast,
              "#004445",
              "breakfast_page_plates",
              true //has legends on top
            )}
            {this.renderMeal(
              "lunch",
              dicionary[this.props.selectedLanguage].title_lunch,
              "#004445",
              "lunch_page3",
              false //has legends on top
            )}
            {this.renderMeal(
              "dinner",
              dicionary[this.props.selectedLanguage].title_dinner,
              "#004445",
              "dinner_page3",
              false //has legends on top
            )}
            {this.renderMeal(
              "snacks",
              dicionary[this.props.selectedLanguage].title_snacks,
              "#2C7873",
              "snacks_page3",
              false //has legends on top
            )}

            {this.state.platesVisible ? this.showModalPlates() : null}
          </div>
          <Modal
            title="What's in the prepared foods?"
            style={{ top: 20 }}
            width={"80%"}
            visible={this.state.foodSpecificationVisible}
            onOk={() => this.setFoodSpecification(false)}
            onCancel={() => this.setFoodSpecification(false)}
          >
            <div style={{ color: "#000" }}>
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <strong>Food item </strong>
                  </td>
                  <td>
                    <strong>Type </strong>
                  </td>
                  <td>
                    <strong>Composition </strong>
                  </td>
                </tr>

                {ingredient_list.map((food) => {
                  return (
                    <tr>
                      <td>{food["Food item"]} </td>
                      <td>{food["Type"]} </td>
                      <td>{food["Composition"]} </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Modal>
          <div>
            {/* {this.renderTotalCaloriesChart()} */}
            {this.getRecomendedDailyCalories()
              ? this.renderTotalCaloriesChart()
              : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <Link to="/survey_page_1">
              <div
                style={{
                  textAlign: "center",
                  width: "171px",
                  height: "63px",
                  //background: "rgb(255, 255, 255)",
                  border: "#004445",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderRadius: "4px",
                  color: "#004445",
                  textTransform: "uppercase",
                  padding: "20px",
                  margin: "5px",
                }}
              >
                {" "}
                {dicionary[this.props.selectedLanguage].previous}
              </div>
            </Link>
            <Link
              to="/survey_page_3"
              onClick={(e) =>
                this.checkIfAllFormIsReadyToSubmit() ? null : e.preventDefault()
              }
            >
              <div
                style={{
                  textAlign: "center",
                  width: "171px",
                  height: "63px",
                  border: "#004445",
                  background: this.checkIfAllFormIsReadyToSubmit()
                    ? "#004445"
                    : "#aaa",
                  borderStyle: "solid",
                  borderWidth: "2px",
                  borderRadius: "4px",
                  color: "#fff",
                  textTransform: "uppercase",
                  padding: "20px",
                  margin: "5px",
                }}
                onClick={async () => {
                  if (this.checkIfAllFormIsReadyToSubmit()) {
                    // let localSurvey = {
                    //   date: new Date(Date.now()),
                    //   answers: this.props.answers,
                    //   foodGroups: this.state.foodGroupsCountingsPerYear
                    // };
                    //console.log("sending: ", localSurvey);
                    //await sendSurvey(localSurvey)
                  } else {
                    message.error(
                      dicionary[this.props.selectedLanguage]
                        .please_fill_correctly
                    );
                  }
                }}
              >
                {dicionary[this.props.selectedLanguage].next}
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default Survey_Page_3;
